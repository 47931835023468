<template>
  <div class="real-estate-rent-contract">
    <b-row>
      <!--Progress-->
      <b-col cols="12">
        <b-card>
          <div>
            <div class="d-flex justify-content-between align-items-center mb-1 card-header">
              <div class="d-flex">
                <feather-icon icon="ListIcon" size="19"/>
                <h4 class="mb-0 ml-50">
                  {{ msg('Progress').toUpperCase() }}
                </h4>
              </div>
              <div v-if="contract != null">
                <b-badge v-bind:variant="variantColorByProgress[progress]">
                  <div v-if="progress<100" class="d-inline-block">
                    <i class="fas fa-clock-rotate-left"></i>
                    {{ msg('In progress') }}
                  </div>
                  <div v-else class="d-inline-block">
                    <i class="fas fa-check"></i>
                    {{ msg('Completed') }}
                  </div>
                  <i class="fas fa-greater-than mx-1"></i>
                  {{ msg(contract.status) }}
                </b-badge>
              </div>
            </div>
            <div class="mt-2" v-if="contract != null">
              <b-progress height="1rem"
                          v-bind:variant="statusData(contract.status) != null ? statusData(contract.status).variant: 'primary'"
                          v-bind:animated="statusData(contract.status) != null ? statusData(contract.status).animated: true"
                          size="lg" v-bind:value="progress" :max="100" show-progress/>
              <b-row class="mt-2">
                <b-col cols="3">

                  <b-list-group>
                    <b-list-group-item class="d-flex justify-content-between align-items-center text-capitalize">
                      <b-form-checkbox :indeterminate="true" disabled>{{ msg("paid") }}</b-form-checkbox>
                      <b-badge :variant="progressByKey['contract']<100?'danger':'success'" pill>
                        {{ progressByKey["contract"] }}%
                      </b-badge>
                    </b-list-group-item>
                  </b-list-group>

                </b-col>

                <b-col cols="3">
                  <b-list-group>
                    <b-list-group-item class="d-flex justify-content-between align-items-center text-capitalize">
                      <b-form-checkbox :indeterminate="true" disabled>{{ msg("Passport") }}</b-form-checkbox>
                      <b-badge :variant="progressByKey['passport']<100?'danger':'success'" pill>
                        {{ progressByKey["passport"] }} %
                      </b-badge>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>


                <b-col cols="3">
                  <b-list-group>
                    <b-list-group-item class="d-flex justify-content-between align-items-center text-capitalize">
                      <b-form-checkbox :indeterminate="true" disabled>{{ msg("Card Authorization") }}</b-form-checkbox>
                      <b-badge :variant="progressByKey['deposit']<100?'danger':'success'" pill>
                        {{ progressByKey["deposit"] }} %
                      </b-badge>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>


                <b-col cols="3">
                  <b-list-group>
                    <b-list-group-item class="d-flex justify-content-between align-items-center text-capitalize">
                      <b-form-checkbox :indeterminate="true" disabled>{{ msg("signed") }}</b-form-checkbox>
                      <b-badge :variant="progressByKey['signature']<100?'danger':'success'" pill>
                        {{ progressByKey["signature"] }} %
                      </b-badge>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>

              </b-row>
            </div>
          </div>
        </b-card>
      </b-col>
      <!--Property-->
      <b-col cols="12" xl="6">
        <div class="h-100 pb-2">
          <b-card class="h-100">
            <div>
              <div class="d-flex justify-content-between align-items-center mb-1 card-header">
                <div class="d-flex">
                  <feather-icon icon="HomeIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('Property').toUpperCase() }}
                  </h4>
                </div>
                <div v-if="contract != null">
                  <b-form-checkbox :disabled="!canEdit" v-model="approve.contract.property"
                                   @change="submitApproveStatus">{{ msg("Approved") }}
                  </b-form-checkbox>
                </div>
              </div>
              <div v-if="property != null">
                <div>
                  <div class="w-100 image-cover mb-2" style="height: 350px"
                       v-bind:style="{'background': `url(${imageSrc}) no-repeat center center `}"/>
                  <div>
                    <b-table-simple>
                      <b-tr>
                        <b-td class="border-top-0"><i class="icon fas fa-tag"></i> Id:</b-td>
                        <b-td class="border-top-0">{{ property.id }}</b-td>
                      </b-tr>

                      <tr role="row">
                        <b-td style="white-space: nowrap"><i class="icon fas fa-map-marker-alt"></i> {{
                            msg("Address")
                          }}:
                        </b-td>
                        <b-td>{{ property.address }} {{ property.house }}, {{ property.city }},
                          {{ property.country }}
                        </b-td>
                      </tr>
                      <tr v-if="property.realEstateTreasure!=null" role="row">
                        <b-td style="white-space: nowrap"><i class="icon fas fa-sign-in-alt"></i> {{ msg("Details") }}:
                        </b-td>
                        <b-td>
                          <div class="max-lines-2" v-if="!showFullText">
                            {{ property.realEstateTreasure.keyCode }}
                          </div>
                          <div v-else>
                            {{ property.realEstateTreasure.keyCode }} {{ property.realEstateTreasure.additionalInfo }}
                          </div>
                          <a href="#" class="read-more"
                             @click.prevent="toggleText">{{ showFullText ? msg('Read less') : msg('Read more') }}</a>
                        </b-td>
                      </tr>


                    </b-table-simple>
                    <b-table-simple class="mt-1">
                      <b-tr>
                        <b-td>
                          <b-button variant="outline-warning" size="sm" @click="generateAndSendLinkButton">
                            {{ msg("Send platform link and credentials to client") }}
                          </b-button>
                        </b-td>
                        <b-td>
                          <b-button style="float: right" variant="outline-warning" size="sm"
                                    @click="sendCheckInDetailsButton">
                            {{ msg("Send CheckIn details to client") }}
                          </b-button>
                        </b-td>

                      </b-tr>
                    </b-table-simple>
                    <b-table-simple class="mt-1">
                      <b-tr>

                        <b-td>
                          <b-button variant="outline-primary" v-b-tooltip :title="msg('Send SMS to client')"
                                    @click="openSmsDialog">SMS
                          </b-button>
                        </b-td>

                        <b-td>
                          <b-button style="float: right" variant="outline-primary"
                                    @click="openInvoicePage">{{ msg('Go to invoice') }}
                          </b-button>
                        </b-td>
                        <b-td>
                          <b-button style="float: right" variant="outline-primary"
                                    @click="openCleaningProgressPage">{{ msg('Go to cleaning progress') }}
                          </b-button>
                        </b-td>

                      </b-tr>
                    </b-table-simple>
                  </div>
                </div>
              </div>
              <div v-else></div>
            </div>
          </b-card>
        </div>
      </b-col>
      <!--Client-->
      <b-col cols="12" xl="6">
        <div class="h-100 pb-2">
          <b-card class="h-100">
            <div>
              <div class="card-header d-flex justify-content-between align-items-center">
                <div class="d-flex">
                  <feather-icon icon="UserIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('Client').toUpperCase() }}
                  </h4>
                </div>
                <div v-if="contract != null">
                  <b-form-checkbox class="d-inline-block" v-bind:disabled="!canEdit || approve.contract.client === true"
                                   v-model="editableFields.client" @change="submitUpdateEditable">
                    <span v-bind:title="msg('ClientCanUpdate')" v-b-tooltip>{{ msg("Editable") }}</span>
                  </b-form-checkbox>
                  <b-form-checkbox class="d-inline-block mx-1" :disabled="!canEdit || editableFields.client === true"
                                   v-model="approve.contract.client" @change="submitApproveStatus">{{
                      msg("Approved")
                    }}
                  </b-form-checkbox>
                </div>
              </div>

              <div v-if="contract != null">
                <app-collapse accordion type="margin">

                  <app-collapse-item :title="msg('Profile - Client details for the contract')" is-visible>
                    <b-table-simple responsive>
                      <b-tr>
                        <b-td><i class="icon fas fa-tag"></i> Id:</b-td>
                        <b-td>{{ client.id }}
                          <b-button size="sm" variant="link" @click="openClientAccount"><i
                              class="fa-solid fa-right-to-bracket"></i></b-button>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td><i class="icon fas fa-calendar-day"></i> {{ msg("Register date") }}:</b-td>
                        <b-td>{{ client.creationTime }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td><i class="icon fas fa-user"></i> {{ msg("Firstname") }}:</b-td>
                        <b-td class="text-capitalize">
                          <b-input-group size="sm">
                            <b-form-input v-model="contract.client.firstname"></b-form-input>
                          </b-input-group>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td><i class="icon fas fa-user"></i> {{ msg("Lastname") }}:</b-td>
                        <b-td class="text-capitalize">
                          <b-input-group size="sm">
                            <b-form-input v-model="contract.client.lastname"></b-form-input>
                          </b-input-group>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-birthday-cake"></i> {{ msg("Client") }} {{ msg("Birthday") }}:
                        </b-td>
                        <b-td>
                          <calendar-picker v-model="contract.client.birthday" mode="date"
                                           :max-date="$moment().subtract(18, 'year').toDate()"/>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-at"></i> {{ msg("Contract Email") }}:</b-td>
                        <b-td>
                          <b-input-group size="sm">
                            <b-form-input v-model="contract.client.email"></b-form-input>
                          </b-input-group>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-at"></i> {{ msg("Client Email") }}:</b-td>
                        <b-td style="font-size: 12px">{{ client.email }}</b-td>

                      </b-tr>
                      <b-tr>
                        <b-td><i class="icon fas fa-phone-alt"></i> {{ msg("Phone") }}:</b-td>
                        <b-td>
                          <b-input-group size="sm">
                            <b-form-input v-model="client.phone"></b-form-input>
                          </b-input-group>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-university"></i> {{ msg("address") }}:</b-td>
                        <b-td>
                          <b-input-group size="sm">
                            <b-form-input v-model="contract.client.address.street"></b-form-input>
                          </b-input-group>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-university"></i> {{ msg("city") }}:</b-td>
                        <b-td>
                          <b-input-group size="sm">
                            <b-form-input v-model="contract.client.address.city"></b-form-input>
                          </b-input-group>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-university"></i> {{ msg("Zip") }}:</b-td>
                        <b-td>
                          <b-input-group size="sm">
                            <b-form-input v-model="contract.client.address.zip"></b-form-input>
                          </b-input-group>
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td><i class="icon fas fa-university"></i> {{ msg("country") }}:</b-td>
                        <b-td>
                          <b-input-group size="sm">
                            <b-form-input v-model="contract.client.address.country"></b-form-input>
                          </b-input-group>
                        </b-td>
                      </b-tr>

                    </b-table-simple>
                  </app-collapse-item>
                  <app-collapse-item title="Job">
                    <div>
                      <b-table-simple responsive>
                        <b-tr>
                          <b-td><i class="icon fas fa-suitcase"></i> {{ msg("Job Title") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input v-model="contract.income.jobTitle"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-university"></i> {{ msg("Company") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input v-model="contract.income.companyName"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>


                        <b-tr>
                          <b-td><i class="icon fas fa-university"></i> {{ msg("Company street") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input v-model="contract.income.companyAddress.street"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-university"></i> {{ msg("Company city") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input v-model="contract.income.companyAddress.city"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-university"></i> {{ msg("Company country") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input v-model="contract.income.companyAddress.country"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-file-invoice"></i> {{ msg("Netto income") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input type="number" v-model="contract.income.net"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-user-clock"></i> {{ msg("Seniority") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input type="number" v-model="contract.income.seniority"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </div>
                  </app-collapse-item>

                  <app-collapse-item v-if="contract.card!=null" title="Card">
                    <div>
                      <b-table-simple responsive>
                        <b-tr>
                          <b-td><i class="icon fas fa-file-invoice"></i> {{ msg("Name") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input v-model="contract.card.name"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-user-clock"></i> {{ msg("Number") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input v-model="contract.card.number"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("Date") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input v-model="contract.card.date"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("Cvv") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input v-model="contract.card.cvv"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </div>
                  </app-collapse-item>
                  <app-collapse-item
                      :title="'KSV'">
                    <div>

                      <b-table-simple responsive>
                        <b-tr>
                          <b-td><i class="icon fas fa-file-invoice"></i> {{ msg("Inkasso open") }}:</b-td>
                          <b-td>
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="ksv.inkasso"
                                name="checkbox-1"
                                :value="true"
                                :unchecked-value="false"
                                disabled

                            >
                            </b-form-checkbox>

                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-user-clock"></i> {{ msg("Insolvency") }}:</b-td>
                          <b-td>
                            <b-form-checkbox
                                id="checkbox-2"
                                v-model="ksv.insolvency"
                                name="checkbox-2"
                                :value="true"
                                disabled
                                :unchecked-value="false"
                            >
                            </b-form-checkbox>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("Commercial Functions") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input readonly v-model="ksv.commercialFunctions"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("Land register") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input readonly v-model="ksv.landRegister"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("Open loans") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input readonly v-model="ksv.openLoans"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("Company participations") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input readonly v-model="ksv.companyParticipations"></b-form-input>
                            </b-input-group>
                          </b-td>
                        </b-tr>

                        <b-tr>
                          <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("KSV Score") }}:</b-td>
                          <b-td>
                            <b-input-group size="sm">
                              <b-form-input readonly v-model="ksv.ksvScore"></b-form-input>
                            </b-input-group>
                          </b-td>

                        </b-tr>

                        <b-td>

                          <template v-if="ksv.status==null ||ksv.status==='NOT APPLIED YET'">
                            <b-button style="white-space: nowrap" variant="outline-warning" size="sm"
                                      @click="createOrFetchKsvData">
                              {{ msg("Apply for KSV data (6€/Request)") }}
                            </b-button>
                          </template>
                          <template v-else-if="ksv.status==='APPLICATIONCREATED'">
                            <b-button disabled style="white-space: nowrap" variant="outline-warning" size="sm">
                              {{ msg("Successfully applied please wait") }}
                            </b-button>
                          </template>
                          <template v-else>
                            <b-button disabled style="white-space: nowrap" variant="outline-warning" size="sm">
                              {{ msg("Data fetched successfully") }}
                            </b-button>
                          </template>
                        </b-td>

                      </b-table-simple>
                    </div>
                  </app-collapse-item>
                </app-collapse>
                <b-table-simple class="mt-3">
                  <b-tr>

                    <b-td class="text-right">
                      <b-button variant="outline-primary" size="sm" @click="submitContract">
                        {{ msg("Update") }}
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-table-simple>

              </div>
            </div>

          </b-card>
        </div>
      </b-col>
      <!--Contract-->
      <b-col cols="12" xl="6">
        <div class="h-100 pb-2">
          <b-card class="h-100">
            <div>
              <div class="card-header d-flex justify-content-between align-items-center">
                <div class="d-flex">
                  <feather-icon icon="FileIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('Contract').toUpperCase() }}
                  </h4>
                </div>
                <div v-if="contract != null" class="mt-2">
                  <b-form-checkbox class="d-inline-block"
                                   v-bind:disabled="!canEdit || approve.contract.contractData === true"
                                   v-model="editableFields.contract" @change="submitUpdateEditable">
                    <span v-bind:title="msg('ClientCanUpdate')" v-b-tooltip>{{ msg("Editable") }}</span>
                  </b-form-checkbox>
                  <b-form-checkbox class="d-inline-block mx-1" :disabled="!canEdit || editableFields.contract === true"
                                   v-model="approve.contract.contractData" @change="submitApproveStatus">
                    {{ msg("Approved") }}
                  </b-form-checkbox>

                </div>
                <!--                <div v-if="contract != null" class="mt-2">
                                  <div class="d-flex flex-wrap">
                                    <div class="d-inline-block">
                                      <b-button size="sm" variant="outline-success"
                                                :disabled="contract.status!=='WAITING_FOR_OWNER_OFFER'"
                                                class="mb-2" style="white-space: nowrap"
                                                @click="requestSigningTheOffer" v-if="permissions.allowOwnerSign"
                                      >
                                        1. {{ msg('signoffer') }}
                                      </b-button>
                                    </div>
                                    <div class="d-inline-block">
                                      <b-button size="sm" variant="outline-warning" class="mb-2" style="white-space: nowrap"
                                                @click="this.$refs.contractGenerator.onContractPartsRequest"
                                                v-if="permissions.allowOwnerSign"
                                      >
                                        2. {{ msg('createcontract') }}
                                      </b-button>
                                    </div>
                                    <div class="d-inline-block">
                                      <b-button size="sm" variant="outline-success" class="mb-2" style="white-space: nowrap"
                                                @click="this.$refs.contractGenerator.onContractRequest"
                                                v-if="permissions.allowOwnerSign"
                                      >
                                        3. {{ msg('signcontract') }}
                                      </b-button>
                                    </div>
                                    <div class="d-inline-block mr-1">
                                      <b-button size="sm" variant="outline-danger" class="mb-2" style="white-space: nowrap"
                                                @click="openCancelModal"
                                                :disabled="contract.status==='CANCELED' || contract.bnbStyleRent.platform!=='Treasure Home'">
                                        {{ msg('cancelcontract') }}
                                      </b-button>
                                    </div>
                                    <div class="d-inline-block">
                                      <b-button size="sm" variant="outline-warning" class="mb-2" style="white-space: nowrap"
                                                @click="openChangeModal"
                                                :disabled="contract.status==='CANCELED'"
                                      >
                                        {{ msg('changecontract') }}
                                      </b-button>
                                    </div>
                                  </div>
                                  <div class="d-inline-block">
                                    <b-button size="sm" variant="outline-success" class="mb-2" style="white-space: nowrap"
                                              @click="openPdfModal"
                                              :disabled="contract.status==='CANCELED'"
                                    >
                                      {{ msg('Generate Invoice - TEST ONLY') }}
                                    </b-button>
                                  </div>

                                </div>-->
              </div>
              <b-modal v-model="cancelModal.show" :id="cancelModal.id" :title="msg('Confirmation')"
                       @ok="cancelContractById()"
                       ok-variant="danger" :cancel-title="msg('cancel')" @hide="resetCancelModal">
                <p class="my-4">{{ msg('areyousureyouwanttocancelthiscontract') }}</p>
              </b-modal>


              <b-modal v-model="changeModal.show" :id="changeModal.id" :title="msg('Confirmation')"
                       @ok="changeContractById()"
                       :ok-disabled="newPropertyId == null || hostawayApi == null"
                       ok-variant="danger" :cancel-title="msg('cancel')" @hide="resetChangeModal">
                <b-col cols="12" md="12" lg="12">
                  <b-form-group :label="msg('Export contract to channels')" label-for="realEstate-name">
                    <b-form-checkbox v-model="hostawayApi" name="check-button" switch>
                      {{ hostawayApi ? msg('Yes') : msg('No') }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <b-form-group :label="msg('searchimmofornewcontract')" label-for="realEstate-name">
                    <real-estate-picker v-model="newPropertyId"/>
                  </b-form-group>
                </b-col>
                <p class="my-4">{{ msg('areyousureyouwanttochangethiscontract') }}</p>
              </b-modal>
              <b-modal v-model="pdfModal.show" :id="pdfModal.id" :title="msg('Confirmation PDF INVOICE')"
                       @ok="generatePdfInvoice()"
                       ok-variant="danger" :cancel-title="msg('cancel')" @hide="resetPdfModal">
                <p class="my-4">{{ msg('test pdf') }}</p>
              </b-modal>
              <sign-offer ref="sign-offer" v-if="contract != null" v-bind:contract="contract"
              />
              <div class="mt-2" v-if="contract != null">
                <b-table-simple>
                  <b-tr>
                    <b-td class="border-top-0"><i class="icon fas fa-tag"></i> Id:</b-td>
                    <b-td class="border-top-0">{{ contract.id }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="border-top-0"><i class="icon fas fa-tag"></i>External Id:</b-td>
                    <b-td class="border-top-0">{{ contract.hostawayReservationId }}</b-td>
                  </b-tr>

                  <b-tr>
                    <b-td class="border-top-0"><i class="icon fas fa-tag"></i>Platform Id:</b-td>
                    <b-td class="border-top-0">{{ contract.platformReservationId }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-calendar-day"></i> {{ msg("Register date") }}:</b-td>
                    <b-td>{{ $moment(contract.creationTime).format("DD/MM/yy HH:mm") }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-user"></i> {{ msg("Client") }}:</b-td>
                    <b-td class="text-capitalize">
                      <b-link @click="()=>$root.$emit('crm::client::select',client.id)">
                        {{ contract.client.firstname }} {{ contract.client.lastname }}
                      </b-link>

                      <span v-if="contract.client.birthday != null">
                      ({{ $moment().diff(contract.client.birthday, 'years') }})
                    </span>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-home"></i> {{ msg("Property") }}:</b-td>
                    <b-td>{{ property.address }} {{ property.house }}, {{ property.city }},
                      {{ property.country }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-clipboard"></i> {{ msg("Usage") }}:</b-td>
                    <b-td>{{ contract.use }}</b-td>
                  </b-tr>
                  <b-tr v-if="contract.bnbStyleRent!=null&&contract.bnbStyleRent.active">
                    <b-td><i class="icon fas fa-user"></i> {{ msg("Platform") }}:</b-td>
                    <b-td>
                      {{ contractEdit.bnbStyleRent.platform }}
                    </b-td>
                  </b-tr>

                  <b-tr>
                    <b-td><i class="icon fas fa-user"></i> {{ msg("Landlord Firstname") }}:</b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <b-form-input readonly v-model="contractEdit.landlord.firstname"></b-form-input>
                      </b-input-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-user"></i> {{ msg("Landlord Lastname") }}:</b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <b-form-input readonly v-model="contractEdit.landlord.lastname"></b-form-input>
                      </b-input-group>
                    </b-td>
                  </b-tr>

                  <b-tr>
                    <b-td><i class="icon fas fa-birthday-cake"></i> {{ msg("Landlord") }} {{ msg("Birthday") }}:</b-td>
                    <b-td>
                      <calendar-picker disabled v-model="contractEdit.landlord.dateOfbirth" mode="date"
                                       :max-date="$moment().subtract(18, 'year').toDate()"/>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-map-marker-alt"></i> {{ msg("Landlord") }} {{ msg("Address") }}:</b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <b-form-input readonly v-model="contractEdit.landlord.fullAddress"></b-form-input>
                      </b-input-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-phone-alt"></i> {{ msg("Landlord") }} {{ msg("Phone") }}:</b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <b-form-input readonly v-model="contractEdit.landlord.phone"></b-form-input>
                      </b-input-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-phone-alt"></i> {{ msg("Landlord") }} {{ msg("Iban") }}:</b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <b-form-input readonly v-model="contractEdit.landlord.iban"></b-form-input>
                      </b-input-group>
                    </b-td>
                  </b-tr>

                  <b-tr v-if="contract.bnbStyleRent!=null&&contract.bnbStyleRent.active">
                    <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("Period price") }}:</b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text><strong>€</strong></b-input-group-text>
                        </template>
                        <b-form-input readonly v-model="contractEdit.bnbStyleRent.totalPayment" number></b-form-input>
                      </b-input-group>
                    </b-td>
                  </b-tr>

                  <b-tr v-if="contract.bnbStyleRent!=null&&contract.bnbStyleRent.active">
                    <b-td><i class="icon fas fa-money-check-alt"></i> {{ msg("periodpriceafterdis") }}:</b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text><strong>€</strong></b-input-group-text>
                        </template>
                        <b-form-input readonly v-model="contractEdit.bnbStyleRent.totalPaymentDiscount"
                                      number></b-form-input>
                      </b-input-group>
                    </b-td>
                  </b-tr>


                  <b-tr v-if="contract.bnbStyleRent!=null&&contract.bnbStyleRent.active">
                    <b-td><i class="icon fas fa-moon"></i> {{ msg("TotalNights") }}:</b-td>
                    <b-td>

                      <b-form-input readonly v-model="contractEdit.bnbStyleRent.totalNights" number></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr v-if="contract.bnbStyleRent!=null&&contract.bnbStyleRent.active">
                    <b-td><i class="icon fas fa-users"></i> {{ msg("Guests") }}:</b-td>
                    <b-td>

                      <b-form-input readonly v-model="contractEdit.numberOfGuests" number></b-form-input>
                    </b-td>
                  </b-tr>

                  <b-tr>
                    <b-td><i class="icon fas fa-money-check-alt"></i>
                      {{ msg("Platform commision") }}:
                    </b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text><strong>€</strong></b-input-group-text>
                        </template>
                        <b-form-input v-model="contractEdit.bnbStyleRent.commissionAmount" number
                                      readonly></b-form-input>
                      </b-input-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="fa-solid fa-money-bill-transfer"></i>
                      {{ msg("Payment Processing Fee") }}:
                    </b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text><strong>€</strong></b-input-group-text>
                        </template>
                        <b-form-input readonly v-model="contractEdit.bnbStyleRent.paymentProcessingFee"
                                      number></b-form-input>
                      </b-input-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-calendar-day"></i> {{ msg("Move Date") }}:</b-td>
                    <b-td>
                      <calendar-picker disabled v-model="contractEdit.moveDate"/>
                    </b-td>
                  </b-tr>

                  <b-tr>
                    <b-td><i class="icon fas fa-calendar-day"></i> {{ msg("CheckOut") }}:</b-td>
                    <b-td>
                      <calendar-picker disabled v-model="contractEdit.endDate"/>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="fa-solid fa-money-bill-transfer"></i>
                      {{ msg("Deposit") }}:
                    </b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <template #append>
                          <b-input-group-text><strong>€</strong></b-input-group-text>
                        </template>
                        <b-form-input readonly v-model="computedDeposit"
                                      number></b-form-input>
                      </b-input-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="fa-solid fa-cancel"></i>
                      {{ msg("Cancellation policy") }}:
                    </b-td>
                    <b-td>
                      <b-input-group size="sm">
                        <span >{{contractEdit.cancelationOptionPicked!=null? msg(contractEdit.cancelationOptionPicked) : msg('nonRefundable')}}</span>
                      </b-input-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      <b-button variant="outline-warning" size="sm" @click="setContractEdit">{{
                          msg("Reset")
                        }}
                      </b-button>
                    </b-td>
                    <b-td class="text-right">
                      <b-button variant="outline-primary" size="sm" @click="submitContract">{{
                          msg("Update")
                        }}
                      </b-button>
                    </b-td>
                  </b-tr>

                </b-table-simple>

              </div>
            </div>
          </b-card>
        </div>

      </b-col>
      <!--Time line-->
      <b-col cols="12" xl="6">
        <div class="h-100 pb-2">
          <b-card class="h-100" ref="add-status-histories">
            <div>
              <div class="d-flex">
                <feather-icon icon="ClockIcon" size="19"/>
                <h4 class="mb-0 ml-50 mb-1">
                  {{ msg('Time line').toUpperCase() }}
                </h4>
              </div>
              <div class="mt-2 position-relative" v-if="contract != null">
                <!--timeline-->
                <perfect-scrollbar class="position-relative" style="height: 300px" :options="{maxScrollbarLength:100}">
                  <app-timeline v-for="(statusHistory,index) in contract.statusHistories" v-bind:key="index">
                    <app-timeline-item class="mb-2" is-title
                                       :title="msg(statusHistory.status)"
                                       :time="statusHistory.time | moment('DD/MM/YY HH:mm:ss')"
                                       :variant="statusData(statusHistory.status).variant"
                    >
                      <template v-slot:subtitle>
                        <p class="text-muted text-capitalize" style="font-size: 12px;line-height: 10px"
                           v-if="statusHistory.source != null">
                          {{ (statusHistory.source || "").toLowerCase() }}
                          {{ statusHistory.userId > 0 ? `: ${getUserFullName(statusHistory.userId)}` : '' }}
                        </p>
                        <p class="m-0">{{ statusHistory.note }}</p>
                      </template>
                    </app-timeline-item>
                  </app-timeline>
                </perfect-scrollbar>
                <!--edit-->
                <b-table-simple>
                  <b-tr>
                    <b-td><i class="icon fas fa-step-forward"></i> {{ msg("Step") }}:</b-td>
                    <b-td>
                      <v-select
                          :dir="isRTL ? 'rtl' : 'ltr'"
                          v-model="newHistory.step"
                          v-bind:options="contractSteps"
                          class="w-100 select-size-sm"
                          label="name"
                          :reduce="(step) => step.value"
                          :selectable="step => step.active"
                          autocomplete="on"
                          :clearable="false"
                          style="min-width: 130px"
                          size="sm"
                          @input="onStepChange"
                      >
                        <template #selected-option="step">
                          <div>
                            <i v-bind:class="[step.icon]" class="mr-1"></i>
                            {{ msg(step.name) }}
                          </div>

                        </template>
                        <template #option="step">
                          <div>
                            <i v-bind:class="[step.icon]" class="mr-1"></i>
                            {{ msg(step.name) }}
                          </div>

                        </template>
                      </v-select>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-bookmark"></i> {{ msg("Status") }}:</b-td>
                    <b-td>
                      <v-select
                          :dir="isRTL ? 'rtl' : 'ltr'"
                          v-model="newHistory.status"
                          v-bind:options="contractsStatus"
                          class="w-100 select-size-sm"
                          label="label"
                          :reduce="(status) => status.label"
                          :selectable="status => status.active"
                          autocomplete="on"
                          :clearable="false"
                          style="min-width: 130px"
                          size="sm"
                      />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><i class="icon fas fa-bookmark"></i> {{ msg("Note") }}:</b-td>
                    <b-td>
                      <b-form-textarea v-model="newHistory.note"></b-form-textarea>
                      <div class="mt-1">
                        <div class="d-inline-block mx-1">
                          <b-form-checkbox v-model="newHistory.visibleToClient" name="check-button" size="sm" switch>
                            {{ msg("Visible to client") }}
                          </b-form-checkbox>
                        </div>
                        <div class="d-inline-block mx-1">
                          <b-form-checkbox v-model="newHistory.visibleToOwner" name="check-button" size="sm" switch>
                            {{ msg("Visible to owner") }}
                          </b-form-checkbox>
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      <b-button variant="outline-warning" size="sm" @click="resetNewHistory">{{
                          msg("Reset")
                        }}
                      </b-button>
                    </b-td>
                    <b-td class="text-right">
                      <b-button variant="outline-primary" size="sm" @click="submitStatus">{{ msg("Update") }}</b-button>
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </div>
            </div>
          </b-card>
        </div>
      </b-col>
      <!--HOSTAWAY CHAT-->
      <b-col cols="12" v-if="contract != null">
        <b-card>
          <b-card-header class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <feather-icon icon="SendIcon" size="19" style="color: #FEA47F"/>
              <h4 class="mb-1 ml-50">
                {{
                  (contract.bnbStyleRent != null && contract.bnbStyleRent.platform != null) ? (msg(contract.bnbStyleRent.platform).toUpperCase() + ' CHAT') : 'EXTERNAL CHAT'
                }}
              </h4>
            </div>
          </b-card-header>
          <div>
            <hostaway-chat-component v-bind:contractId="id" v-bind:reservation-id="contract.hostawayReservationId" v-bind:contract="contract"/>
          </div>
        </b-card>
      </b-col>
      <!--whatsapp CHAT-->
      <b-col cols="12" v-if="contract != null">
        <b-card>
          <b-card-header class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <i class="fab fa-whatsapp" style="color: #FEA47F;font-size: 19px"/>
              <h4 class="mb-1 ml-50">WHATSAPP CHAT</h4>
            </div>
          </b-card-header>
          <div>
            <whatsapp-chat-component v-bind:clientId="contract.clientId" v-bind:whatsapp="contract.whatsappChat"/>
          </div>
        </b-card>
      </b-col>

      <!--Message templates history-->
      <b-col cols="12" v-if="contract != null">
        <b-card>
          <b-card-header class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <i class="fas fa-history" style="color: #FEA47F;font-size: 19px"/>
              <h4 class="mb-1 ml-50">Message Template History</h4>
            </div>

          </b-card-header>
          <div>
            <message-template-history-component v-bind:history="contract.messageTemplateHistory"
                                                :contract-id="contract.id"/>
          </div>
        </b-card>
      </b-col>
      <!--Guarantors-->
      <!--      <b-col cols="12" xl="6">
              <div class="h-100 pb-2">
                <b-card class="h-100">
                  <div>
                    <div class="card-header d-flex justify-content-between align-items-center">
                      <div class="d-flex">
                        <feather-icon icon="UserIcon" size="19"/>
                        <h4 class="mb-0 ml-50">
                          {{ msg('Guarantors').toUpperCase() }}
                        </h4>
                      </div>
                      <div v-if="contract != null">
                        <b-form-checkbox class="d-inline-block"
                                         v-bind:disabled="!canEdit || approve.guarantors.verified === true"
                                         v-model="editableFields.guarantors" @change="submitUpdateEditable">
                          <span v-bind:title="msg('ClientCanUpdate')" v-b-tooltip>{{ msg("Editable") }}</span>
                        </b-form-checkbox>
                        <b-form-checkbox class="d-inline-block mx-1"
                                         :disabled="!canEdit || editableFields.guarantors === true"
                                         v-model="approve.guarantors.verified" @change="submitApproveStatus">
                          {{ msg("Approved") }}
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="mt-2 position-relative" v-if="contract != null">
                      <b-tabs>
                        &lt;!&ndash;Valid guarantors&ndash;&gt;
                        <template v-if="Array.isArray(contract.guarantors)">
                          <b-tab v-for="(guarantor,index) in contract.guarantors" v-bind:key="guarantor.email">
                            <template #title>
                              <i class="far fa-user-circle"></i>
                              <span class="text-capitalize">{{ guarantor.name }}</span>
                            </template>
                            <div>

                              <b-table-simple class="my-0" responsive>
                                <b-tr>
                                  <b-td><i class="icon fas fa-user"></i> {{ msg("Name") }}</b-td>
                                  <b-td class="text-capitalize">
                                    {{ guarantor.name }}
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td><i class="icon fas fa-at"></i> {{ msg("Email") }}</b-td>
                                  <b-td>{{ guarantor.email }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td><i class="icon fas fa-bookmark"></i> {{ msg("Note") }}</b-td>
                                  <b-td>
                                    <b-form-textarea v-model="guarantor.note"></b-form-textarea>
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td><i class="fas fa-user-edit"></i> {{ msg("ClientCanUpdate") }}</b-td>
                                  <b-td class="text-capitalize">
                                    <b-form-checkbox class="d-inline-block" switch
                                                     v-bind:disabled="!canEdit || editableFields.guarantors !== true"
                                                     v-model="editableFields.guarantor[index]"
                                                     @change="submitUpdateEditable"/>
                                  </b-td>
                                </b-tr>
                              </b-table-simple>
                            </div>
                          </b-tab>
                        </template>
                        &lt;!&ndash;duming data&ndash;&gt;
                        <b-tab v-for="i in (4-(contract.guarantors || []).length)" v-bind:key="i" disabled>
                          <template #title>
                            <i class="far fa-times-circle"></i>
                            <span>{{ msg('None') }}</span>
                          </template>
                        </b-tab>
                        &lt;!&ndash; New Tab Button&ndash;&gt;
                        &lt;!&ndash;                  <template #tabs-end>
                                            <b-nav-item role="presentation" href="#" v-if="(contract.guarantors || []).length < 4">
                                              <i class="fas fa-user-plus"></i><span class="text-capitalize">{{msg("add")}}</span>
                                            </b-nav-item>
                                          </template>&ndash;&gt;

                      </b-tabs>
                      <b-row class="border-top pt-1" no-gutters>
                        <b-col cols="6">
                          <b-button variant="outline-warning" size="sm">{{ msg("Reset") }}</b-button>
                        </b-col>
                        <b-col cols="6" class="text-right">
                          <b-button variant="outline-primary" size="sm">{{ msg("Update") }}</b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-card>
              </div>
            </b-col>
            &lt;!&ndash;Tenant&ndash;&gt;
            <b-col cols="12" xl="6">
              <div class="h-100 pb-2">
                <b-card class="h-100">
                  <div>
                    <div class="card-header d-flex justify-content-between align-items-center">
                      <div class="d-flex">
                        <feather-icon icon="UserIcon" size="19"/>
                        <h4 class="mb-0 ml-50">
                          {{ msg('Tenant').toUpperCase() }}
                        </h4>
                      </div>
                      <div v-if="contract != null">
                        <b-form-checkbox class="d-inline-block"
                                         v-bind:disabled="!canEdit || approve.tenants.verified === true"
                                         v-model="editableFields.tenants" @change="submitUpdateEditable">
                          <span v-bind:title="msg('ClientCanUpdate')" v-b-tooltip>{{ msg("Editable") }}</span>
                        </b-form-checkbox>
                        <b-form-checkbox class="d-inline-block mx-1" :disabled="!canEdit || editableFields.tenants === true"
                                         v-model="approve.tenants.verified" @change="submitApproveStatus">{{
                            msg("Approved")
                          }}
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="mt-2 position-relative" v-if="contract != null">
                      <b-tabs>
                        <template v-if="Array.isArray(contract.tenant)">
                          <b-tab v-for="(tenant,index) in contract.tenant" v-bind:key="tenant.email">
                            <template #title>
                              <i class="far fa-user-circle"></i>
                              <span class="text-capitalize">{{ tenant.name }}</span>
                            </template>
                            <div>
                              <b-table-simple class="my-0" responsive>
                                <b-tr>
                                  <b-td><i class="icon fas fa-user"></i> {{ msg("Name") }}</b-td>
                                  <b-td class="text-capitalize">{{ tenant.name }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td><i class="icon fas fa-at"></i> {{ msg("Email") }}</b-td>
                                  <b-td>{{ tenant.email }}</b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td><i class="icon fas fa-bookmark"></i> {{ msg("Note") }}</b-td>
                                  <b-td>
                                    <b-form-textarea v-model="tenant.note"></b-form-textarea>
                                  </b-td>
                                </b-tr>
                                <b-tr v-show="editableFields.tenants === true">
                                  <b-td><i class="fas fa-user-edit"></i> {{ msg("ClientCanUpdate") }}</b-td>
                                  <b-td class="text-capitalize">
                                    <b-form-checkbox class="d-inline-block" switch
                                                     v-bind:disabled="!canEdit || editableFields.tenants !== true"
                                                     v-model="editableFields.tenant[index]" @change="submitUpdateEditable"/>
                                  </b-td>
                                </b-tr>
                              </b-table-simple>
                            </div>
                          </b-tab>
                        </template>
                        <b-tab v-for="i in (4-(contract.tenant || []).length)" v-bind:key="i" disabled>
                          <template #title>
                            <i class="far fa-times-circle"></i>
                            <span>{{ msg('None') }}</span>
                          </template>
                        </b-tab>
                      </b-tabs>
                      <b-row class="border-top pt-1" no-gutters>
                        <b-col cols="6">
                          <b-button variant="outline-warning" size="sm">{{ msg("Reset") }}</b-button>
                        </b-col>
                        <b-col cols="6" class="text-right">
                          <b-button variant="outline-primary" size="sm">{{ msg("Update") }}</b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-card>
              </div>
            </b-col>-->
      <!--Damages-->

      <!--Payments-->
      <b-col cols="12" v-if="contract != null">
        <b-card>
          <b-card-header class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <!-- Icon and Title -->
              <i class="fas fa-money-bill" style="color: #FEA47F; font-size: 19px;"></i>
              <h4 class="mb-1 ml-2">PAYMENTS</h4>
            </div>

            <div class="d-flex align-items-center">
              <!-- Deposit Paid Checkbox -->
              <b-form-checkbox
                  class="mr-3"
                  v-model="contract.depositPaid"
                  @change="depositPaid('Deposit Paid', contract.depositPaid)"
              >
                Deposit Paid
              </b-form-checkbox>

              <!-- Deposit Refunded Checkbox -->
              <b-form-checkbox
                  class="mr-3"
                  v-model="contract.depositRefunded"
                  @change="depositRefunded('Deposit Refunded', contract.depositRefunded)"
              >
                Deposit Refunded
              </b-form-checkbox>

              <!-- Payment Button -->
              <b-button style="float: right" variant="warning" @click="openPaymentModal">
                {{ msg('Make payment') }}
              </b-button>
            </div>
          </b-card-header>

          <div>
            <payment-client-table :payments="contract.clientPayments"/>
          </div>
        </b-card>
      </b-col>
      <!--Damages-->
      <b-col cols="12">
        <contract-damage-component v-bind:contract-id="id"/>
      </b-col>

      <!--ALERTS-->
      <b-col cols="12">
        <b-card>
          <b-card-header class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <feather-icon icon="AlertCircleIcon" size="19"/>
              <h4 class="mb-0 ml-50">
                {{ msg('Alerts').toUpperCase() }}
              </h4>
            </div>
          </b-card-header>
          <div>
            <contract-alert-component ref="alertComponent" v-bind:contractId="id"/>
          </div>
        </b-card>
      </b-col>


      <!--Files-->
      <b-col cols="12">
        <b-card style="height: 550px;">
          <div>
            <div class="card-header d-flex justify-content-between align-items-center">
              <div class="d-flex">
                <feather-icon icon="FileIcon" size="19"/>
                <h4 class="mb-0 ml-50">
                  {{ msg('Files').toUpperCase() }}
                </h4>
              </div>

              <div v-if="contract != null">
                <b-button size="sm" v-bind:variant="uploadFile ? 'warning' : 'outline-warning'"
                          @click="()=>uploadFile =!uploadFile">
                  <i class="fas fa-upload"></i> {{ msg("Upload file") }}
                </b-button>
              </div>
            </div>
            <div class="mt-2" v-if="contract != null">
              <div class="mb-2">
                <b-alert show class="p-1" variant="secondary"><i style="color: red"
                                                                 class="fas fa-exclamation-circle"></i>
                  {{
                    msg('Be careful with the client files, some files can contain viruses. Do not open any link in the file')
                  }}
                </b-alert>
                <h5>{{ msg("File status") }}</h5>
                <b-form-group>
                  <b-form-checkbox :disabled="!canEdit" inline v-model="approve.document.photo"
                                   @change="submitApproveStatus">
                    {{ msg("Photo ID") }}
                  </b-form-checkbox>
                  <b-form-checkbox :disabled="!canEdit" inline v-model="approve.document.document"
                                   @change="submitApproveStatus">
                    {{ msg("Client Document") }}
                  </b-form-checkbox>
                  <b-form-checkbox :disabled="!canEdit" inline v-model="approve.document.income"
                                   @change="submitApproveStatus">
                    {{ msg("Income") }}
                  </b-form-checkbox>
                  <b-form-checkbox :disabled="!canEdit" inline v-model="approve.document.contract"
                                   @change="submitApproveStatus">
                    {{ msg("Approved contract") }}
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <h5>{{ msg("All files") }}</h5>
              <div class="files-data">
                <div class="upload-backdrop" v-show="uploadFile === true">
                  <vue-dropzone id="contract-upload" class="h-100" :options="dropzoneOptions" :useCustomSlot=true
                                :duplicateCheck="true" @vdropzone-complete="uploadComplete"
                                @vdropzone-success="uploadSuccess">
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                      <div class="subtitle">...or click to select a file from your computer</div>
                    </div>
                  </vue-dropzone>
                </div>
                <b-table-simple outlined>
                  <b-tr>
                    <b-th class="text-center" style="width: 20%">{{ msg('File') }}</b-th>
                    <b-th class="text-center" style="width: 20%">{{ msg('Tag') }}</b-th>
                    <b-th class="text-center" style="width: 20%">{{ msg('Client note') }}</b-th>
                    <b-th class="text-center" style="width: 20%">{{ msg('Note') }}</b-th>
                    <b-th class="text-center" style="width: 10%">{{ msg("Visible") }}</b-th>
                    <b-th class="text-center" style="width: 15%">{{ msg('Operations') }}</b-th>
                  </b-tr>
                </b-table-simple>
                <perfect-scrollbar class="position-relative " style="height: 250px">
                  <b-table-simple outlined>
                    <b-tr v-for="(file,index) in (contract.files || [])" v-bind:key="file.id"
                          style="white-space: nowrap;text-overflow: ellipsis" v-show="file.active">
                      <b-td style="width: 20%">
                        <i v-bind:class="iconByExt(file.filename)" class="mx-1"
                           style="font-size: 20px"></i>{{ file.filename }}
                      </b-td>
                      <b-td class="text-center" style="width: 20%">
                        <v-select :options="fileTags" :reduce="(tag) => tag.value" label="title" v-model="file.tag"
                                  append-to-body @input="()=>submitDocumentFile(index)">
                          <template v-slot:option="option">
                            <i class="d-inline-block text-left" style="width: 30px" :class="option.icon"></i>
                            <span>{{ option.title }}</span>
                          </template>
                        </v-select>
                      </b-td>
                      <b-td class="text-center" style="width: 20%">
                        <b-form-textarea v-model="file.clientNote" @change="()=>submitDocumentFile(index)"
                                         size="sm"></b-form-textarea>
                      </b-td>
                      <b-td class="text-center" style="width: 20%">
                        <b-form-textarea v-model="file.notes" @change="()=>submitDocumentFile(index)"
                                         size="sm"></b-form-textarea>
                      </b-td>
                      <b-td class="text-center" style="width: 10%">
                        <b-form-checkbox v-model="file.visible" class="mx-1" name="check-button" size="sm"
                                         @change="()=>submitDocumentFile(index)"
                                         v-b-tooltip="msg('visible to client')"
                                         button
                                         v-bind:button-variant="file.visible ? 'outline-success' : 'outline-warning'">
                          <i class="fas fa-user"></i>
                        </b-form-checkbox>
                        <b-form-checkbox v-model="file.visibleForOwner" name="check-button" size="sm"
                                         @change="()=>submitDocumentFile(index)"
                                         v-b-tooltip="msg('visible to owner')"
                                         button
                                         v-bind:button-variant="file.visibleForOwner ? 'outline-success' : 'outline-warning'">
                          <i class="fas fa-user-tie"></i>
                        </b-form-checkbox>
                      </b-td>
                      <b-td class="text-center" style="width: 15%">
                        <b-link class="text-secondary mx-1"
                                @click="()=>onHideFile(index)"><i
                            class="fas fa-eye-slash"></i></b-link>
                        <b-link><i class="icon fas fa-download  w-auto" @click="()=>onDownloadFile(file.id)"></i>
                        </b-link>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <contract-generator-component ref="contractGenerator" v-model="contract"
                                  @contractSigned="refresh"
    />

    <b-modal no-fade v-model="dialogs.sms" size="l" ref="sms-modal" id="sms-modal" :hide-footer="true"
             no-close-on-backdrop centered>
      <sms-panel ref="smsPanel" @submitted="onSubmitted" :client-id="client.id"/>
      <div class="mt-2 text-center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitSMS">
          {{ msg('Send SMS') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal v-if="contract!=null" no-fade v-model="dialogs.payment" size="l" ref="payment-modal"
             id="payment-modal" :hide-footer="true"
             :title="'Customer: '+ contract.client.lastname+' '+contract.client.firstname +' - Stripe Payment'"
             no-close-on-backdrop centered>
      <div class="mt-2 text-center">
        <b-form-input v-model="reason"
                      placeholder="Reason"
                      class="mb-2"></b-form-input>
        <b-form-input v-model="paymentAmount"
                      type="number"
                      placeholder="Amount"
                      step="0.01" min="0" max="3000"
                      class="mb-2"></b-form-input>
        <b-button :disabled="paymentAmount==null || paymentAmount<=0.0 || paymentLoading || reason==null"
                  variant="outline-success"
                  class="btn-dialog" @click="makePayment">
          {{ msg('Charge the customer now') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import vue2Dropzone from 'vue2-dropzone'
import VueSlider from 'vue-slider-component'
import ContractGeneratorComponent from "@/views/realEstate/contracts/contractGeneratorComponent";
import ContractAlertComponent from "@/views/realEstate/contracts/ContractAlertComponent";
import signOffer from "@/views/realEstate/contracts/signOffer";
import RealEstatePicker from "@/components/components/picker/realEstatesPicker";
import HostawayChatComponent from "@/views/realEstate/contracts/HostawayChatComponent";
import WhatsappChatComponent from "@/views/realEstate/contracts/WhatsappChatComponent";
import PaymentClientTable from "@/views/realEstate/contracts/paymentClientTable";
import SmsPanel from "@/components/panels/client/smsPanel.vue";
import ContractDamageComponent from "@/views/realEstate/contracts/ContractDamageComponent.vue";
import MessageTemplateHistoryComponent from "@/views/realEstate/contracts/MessageTemplateHistoryComponent.vue";

export default {
  name: "RealEstateRentContractEdit",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    MessageTemplateHistoryComponent,
    ContractDamageComponent,
    SmsPanel,
    ContractAlertComponent,
    ContractGeneratorComponent,
    AppCollapse,
    PaymentClientTable,
    RealEstatePicker,
    HostawayChatComponent,
    WhatsappChatComponent,
    AppCollapseItem,
    signOffer,
    AppTimeline,
    AppTimelineItem,
    // eslint-disable-next-line vue/no-unused-components
    VueSlider,
    vueDropzone: vue2Dropzone,
  },
  data: () => ({
    id: null,
    value: 50,
    contract: null,
    newPropertyId: null,
    hostawayApi: false,
    permissions: {},
    alerts: [],
    cancelModal: {
      id: 'cancel-modal',
      title: '',
      content: '',
      show: false
    },
    changeModal: {
      id: 'change-modal',
      title: '',
      content: '',
      show: false
    }, pdfModal: {
      id: 'pdf-modal',
      title: '',
      content: '',
      show: false
    },
    showFullText: false,
    allContractStatus: [],
    allContractSteps: {},
    ksv: {status: "NOT APPLIED YET"},

    contractEdit: {price: 0, provision: null, moveDate: 0, endDate: null, landlord: {}},
    approve: {},
    editableFields: {client: false, contract: false, guarantors: false, tenants: false, guarantor: [], tenant: []},
    property: null,
    client: {},
    uploadFile: false,
    paymentAmount: 0.0,
    reason: null,
    paymentLoading: false,
    dialogs: {sms: false, payment: false},

    newHistory: {note: "", status: "", step: 0, visibleToClient: true, visibleToOwner: true},
  }),
  created() {
    this.id = this.$route.params.id;
    this.refresh();
  },
  computed: {
    ...mapGetters('data', ['getUserFullName', 'getRealEstatesWebSite']),
    ...mapGetters('realEstateContracts', ['fileTags']),
    computedDeposit: {
      get() {
        // Return the value based on the condition
        return this.contractEdit.deposit != null && this.contractEdit.deposit > 0
            ? this.contractEdit.deposit
            : this.property.realEstatePrices.deposit;
      },
      set(value) {
        // Update the appropriate data property based on the condition
        if (this.contractEdit.deposit != null && this.contractEdit.deposit > 0) {
          this.contractEdit.deposit = value;
        } else {
          this.property.realEstatePrices.deposit = value;
        }
      }
    },
    statusData() {
      let statusMap = this.allContractStatus
          .map(status => ({
            ...status,
            variant: this.allContractSteps[status.step].variant,
            animated: this.allContractSteps[status.step].animated
          }))
          .toObject("name");

      statusMap["APPROVED_OWNER_CHANGES"].variant = "success";
      statusMap["REJECTED_OWNER_CHANGES"].variant = "danger";
      statusMap["APPROVED_CLIENT_SIGNATURE"].variant = "success";
      statusMap["REJECTED_CLIENT_SIGNATURE"].variant = "danger";
      statusMap["CANCELED"].variant = "danger";
      return (status) => ({
        ...(statusMap[status] ? statusMap[status] : statusMap["NEW"]),
        value: status
      });
    },
    canEdit() {
      if (this.contract == null || this.contract.status == null)
        return false;
      return ["REVIEWING", "WAITING_FOR_DATA", "WAITING_FOR_FILE", "COMPLETED", "CANCELED"].indexOf(this.contract.status) >= 0
    },
    dropzoneOptions() {
      if (this.contract == null || this.contract.id == null)
        return {};
      return {
        url: window.config.apiUrl + 'realEstate/contract/upload/file/' + this.contract.id,
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 50000,
        acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx",
      }
    },
    progress() {
      if (this.contract == null)
        return 0;

      let keys = Object.keys(this.progressByKey);

      if (keys.length === 0)
        return 100;

      // Calculate the sum of mapped values and divide by 100 times the length
      let sum = Object.values(this.progressByKey).reduce((acc, val) => acc + val, 0);
      return sum / (keys.length);
    },
    progressByKey() {
      if (this.contract == null || this.$refs.alertComponent.alerts == null || this.$refs.alertComponent.alerts.length === 0)
        return {
          "contract": 100,
          "deposit": 100,
          "passport": 100,
          "signature": this.contract.signedOffer ? 100 : 0,

        };
      return {
        "contract": this.$refs.alertComponent.alerts.filter(a => a.actionRequired === "RENT_PAYMENT").length > 0 ? 0 : 100,
        "deposit": this.$refs.alertComponent.alerts.filter(a => a.actionRequired === "AUTHORIZATION_FEE_PAYMENT").length > 0 ? 0 : 100,
        "passport": this.$refs.alertComponent.alerts.filter(a => a.actionRequired === "UPLOAD_REQUIRED_FILES").length > 0 ? 0 : 100,
        "signature": this.contract.signedOffer ? 100 : 0,
      }
    },
    variantColorByProgress() {
      return {
        100: 'success',
        75: 'warning',
        50: 'warning',
        25: 'danger',
        0: 'danger'

      };
    },
    imageSrc() {
      if (this.property.attachments == null || this.property.attachments[0] == null)
        return null
      if (this.property.attachments[0].attachmentUrl.startsWith('http'))
        return this.property.attachments[0].attachmentUrl

      return this.getRealEstatesWebSite + this.property.attachments[0].attachmentUrl;
    },
    currentStatus() {
      let $this = this;
      return this.allContractStatus.find(s => s.name === $this.contract.status) || {step: 0};
    },
    contractsStatus() {
      let $this = this;
      return this.allContractStatus
          .filter(status => status.step === this.newHistory.step)
          .map(s => ({label: $this.msg(s.name), value: s.name, active: true}));
    },
    contractSteps() {
      let currentStep = this.allContractSteps[this.currentStatus.step];
      if (currentStep == null)
        return [];
      return Object.values(this.allContractSteps).map(step => ({
        ...step,
        active: currentStep.value <= step.value || currentStep.name === "BLOCKED"
      }));
    },

    ksvStatus() {
      return [
        {label: this.msg("Application in progress"), value: "APPLICATIONCREATED"},
        {label: this.msg("Done"), value: "DONE"}

      ]
    },

  },
  methods: {
    ...mapActions("realEstateContracts", ["createPdfInvoiceContract", "getById", "depositWasPaid", "depositWasRefunded", "updateContract", "sproofRequest", "cancelContract", "changeContract",
      "updateStatus", "updateFilesData", "updateApproveStatus", "changeContractOwner",
      "updateEditableFields", "updateDocumentFile", "generateAndSendLink", "sendCheckInDetails", "getMonetaryId", "getCleaningId"]),
    ...mapActions("document", ["downloadFile"]),
    ...mapActions("monetary", ["chargeCustomer"]),
    ...mapActions("ksv", ["createOrFetchKsv", "getKsvById"]),
    ...mapActions("clients", ["generateToken"]),
    depositRefunded(label, value) {
      this.depositWasRefunded({id: this.contract.id, depositRefunded: value});
    },
    depositPaid(label, value) {
      this.depositWasPaid({id: this.contract.id, depositPaid: value});
    },

    resetCancelModal() {
      this.cancelModal.show = false;
    },
    resetChangeModal() {
      this.changeModal.show = false;
      this.newPropertyId = null;
    }, resetPdfModal() {
      this.pdfModal.show = false;
    },
    cancelContractById() {
      let $this = this;
      this.cancelContract({id: this.contract.id}).then(contract => {
        $this.refresh();

      })

    },
    openInvoicePage() {
      let $this = this;
      this.getMonetaryId({id: this.contract.id}).then(monetaryId => {
        window.open(`/report/monetary/invoice/${monetaryId}`, '_blank');
      })

    },
    openCleaningProgressPage() {
      let $this = this;
      this.getCleaningId({id: this.contract.id}).then(cleaningId => {
        if (cleaningId != null)
          window.open(`/cleaning/edit/${cleaningId}`, '_blank');
        else {
          this.$toast.error("Cleaning task not created yet")
        }
      })

    },
    changeContractById() {
      let $this = this;
      this.changeContract({
        id: this.contract.id,
        propertyId: this.newPropertyId,
        hostawayApi: this.hostawayApi
      }).then(contractid => {
        if (!this.hostawayApi)
          $this.$router.push(`/real_estate/contracts/rent/${contractid}`);
        else
          $this.$router.push(`/real_estate/contracts/rent`);
      })

    },
    toggleText() {
      this.showFullText = !this.showFullText;
    },
    generatePdfInvoice() {
      this.createPdfInvoiceContract(this.contract.id)
          .then(console.log("PDF generated"))
    },
    openCancelModal() {
      this.cancelModal.show = true;
    },
    openChangeModal() {
      this.changeModal.show = true;
    },
    openPdfModal() {
      this.pdfModal.show = true;
    },
    refresh() {
      this.getById(this.id).then(this.onContractReceive, this.onContractRetrieveError);
    },
    createOrFetchKsvData() {
      let $this = this;

      this.createOrFetchKsv(this.id).then(ans =>
          $this.ksv = ans["Ksv"]
      );

    },
    onContractReceive(data) {
      let $this = this;
      this.buildApproveData(data.contract);
      this.buildEditableFieldsData(data.contract);
      this.contract = data.contract;
      this.contract.statusHistories = this.contract.statusHistories.sort((a, b) => $this.$moment(b.time, 'YYYY-MM-DD HH:mm:ss').diff($this.$moment(a.time, 'YYYY-MM-DD HH:mm:ss')))
      this.newHistory.status = this.contract.status;
      this.setContractEdit();
      this.property = data.property;
      this.client = data.client;
      this.permissions = data.permissions;
      this.allContractStatus = data.statuses;
      this.mergeSteps(data.steps);
      this.newHistory.step = this.allContractSteps[this.currentStatus.step].value;
      if (this.contract.ksvId != null) {
        this.getKsvById(this.contract.ksvId).then(ans => {
          $this.ksv = ans;
        });

      }

      //this.alerts = this.$refs.alertComponent.alerts;

    },

    buildApproveData(contract) {
      let approve = {
        contract: {client: false, property: false, contractData: false},
        document: {document: false, income: false, photo: false, contract: false},
        guarantors: {verified: false},
        tenants: {verified: false}
      }
      if (contract.approve == null) {
        this.approve = approve;
        return contract.approve = approve;
      }
      contract.approve.contract = {...approve.contract, ...(contract.approve.contract || {})}
      contract.approve.document = {...approve.document, ...(contract.approve.document || {})}
      contract.approve.guarantors = {...approve.guarantors, ...(contract.approve.guarantors || {})}
      contract.approve.tenants = {...approve.tenants, ...(contract.approve.tenants || {})}
      this.approve = {...contract.approve};
    },
    buildEditableFieldsData(contract) {
      let editableFields = {
        client: false, contract: false, guarantors: false, tenants: false,
        guarantor: [false, false, false, false], tenant: [false, false, false, false]
      };
      this.editableFields = {...editableFields, ...(contract.editableFields || {})}
    },
    setContractEdit() {
      this.contractEdit.appointment = this.contract.appointment
      this.contractEdit.moveDate = this.contract.moveDate
      this.contractEdit.endDate = this.contract.endDate
      this.contractEdit.numberOfGuests = this.contract.numberOfGuests
      this.contractEdit.smart = this.contract.smart
      this.contractEdit.insurance = this.contract.insurance
      this.contractEdit.gas = this.contract.gas
      this.contractEdit.internet = this.contract.internet
      this.contractEdit.price = this.contract.price
      this.contractEdit.provision = this.contract.provision
      this.contractEdit.income = this.contract.income
      this.contractEdit.bank = this.contract.bank
      this.contractEdit.card = this.contract.card
      this.contractEdit.deposit = this.contract.deposit
      this.contractEdit.depositPaid = this.contract.depositPaid
      this.contractEdit.depositRefunded = this.contract.depositRefunded
      this.contractEdit.client = this.contract.client
      if (this.contract.landlord != null)
        this.contractEdit.landlord = this.contract.landlord
      if (this.contract.bnbStyleRent != null)
        this.contractEdit.bnbStyleRent = this.contract.bnbStyleRent
    },
    mergeSteps(steps) {
      let defaultSteps = {
        0: {name: "START", value: 0, icon: "far fa-flag", variant: "info", animated: false},
        1: {name: "REVIEW", value: 1, icon: "fas fa-list-ul", variant: "info", animated: true},
        2: {name: "OWNER_REVIEW", value: 2, icon: "fas fa-user-tie", variant: "warning", animated: true},
        3: {name: "OWNER_SIGNED", value: 3, icon: "fas fa-file-signature", variant: "success", animated: true},
        4: {name: "WAIT_CLIENT_SIGN", value: 4, icon: "far fa-user", variant: "warning", animated: true},
        5: {name: "CLIENT_SIGNED", value: 5, icon: "fas fa-signature", variant: "success", animated: true},
        7: {name: "BLOCKED", value: 7, icon: "fas fa-ban", variant: "danger", animated: false},
        8: {name: "COMPLETED", value: 8, icon: "far fa-check-circle", variant: "success", animated: false},
        10: {name: "FINALIZED", value: 10, icon: "fas fa-check-double", variant: "success", animated: false},
      }
      this.allContractSteps = {...steps.toObject("value"), ...defaultSteps}
    },
    generateAndSendLinkButton() {
      this.generateAndSendLink(this.id);
    },
    sendCheckInDetailsButton() {
      this.sendCheckInDetails(this.id);
    },
    onContractRetrieveError(error) {

    },
    iconByExt(file) {
      if (file == null) {
        return {fas: true, 'fa-file-alt': true};
      }
      let ext = file.substr(file.lastIndexOf('.') + 1)
      if (ext === 'pdf') {
        return {far: true, 'fa-file-pdf': true}
      }
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gift' || ext === 'bmp') {
        return {far: true, ' fa-file-image': true}
      }
      if (ext === 'doc' || ext === 'docx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'xls' || ext === 'xlsx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'zip' || ext === 'rar' || ext === '7zip') {
        return {far: true, ' fa-file-archive': true}
      }
      return {fas: true, 'fa-file-alt': true}
    },
    resetNewHistory() {
      this.newHistory = {
        ...this.newHistory,
        note: "",
        status: this.contract.status,
        step: this.allContractSteps[this.currentStatus.step].value
      }
    },
    uploadComplete(file, response) {
      console.log('uploadComplete', {file, response})
      let responseData = JSON.parse(file.xhr.responseText);
      let documents = responseData.objects["documents"];
      console.log('uploadComplete', documents);
      this.contract.files = documents;
    },
    uploadSuccess(file, response) {
      try {
        if (response.data == null || response.data['documents'] == null)
          return;
        this.contract.files = response.data['documents'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    },
    onStepChange() {
      let $this = this;
      if (this.contractsStatus.findIndex(status => status.value === $this.newHistory.status) >= 0) {
        return;
      }
      this.newHistory.status = this.contractsStatus.find(status => status.active === true).value
    },
    openSmsDialog() {

      this.dialogs.sms = true

    },
    openPaymentModal() {

      this.dialogs.payment = true

    },
    makePayment() {
      let $this = this;
      this.paymentLoading = true
      this.chargeCustomer({
        contractId: this.contract.id,
        amount: this.paymentAmount,
        reason: this.reason
      }).then(approve => {
        $this.dialogs.payment = false
      }).finally(() => {
            $this.paymentLoading = false
            $this.paymentAmount = 0.0
            $this.reason = null
            $this.dialogs.payment = false
          }
      )

    },
    submitContract() {
      let $this = this;
      if (this.contract.bnbStyleRent != null && this.contract.bnbStyleRent.active) {
        this.contractEdit.bnbStyleRent = this.contract.bnbStyleRent
      }
      this.contractEdit.income = this.contract.income
      this.contractEdit.bank = this.contract.bank
      this.contractEdit.card = this.contract.card
      this.contractEdit.client = this.contract.client
      this.updateContract({id: this.contract.id, contract: this.contractEdit}).then(contract => {
        this.contract.moveDate = contract.moveDate
        this.contract.endDate = contract.endDate
        this.contract.smart = contract.smart
        this.contract.insurance = contract.insurance
        this.contract.gas = contract.gas
        this.contract.internet = contract.internet
        this.contract.price = contract.price
        this.contract.provision = contract.provision
        if (contract.landlord != null)
          this.contract.landlord = contract.landlord
        $this.setContractEdit()
        if (contract.status !== this.contract.status) {
          this.refresh();
        }
      })
    },
    changeOwner() {
      let $this = this;

      this.changeContractOwner({id: this.contract.id, ownerId: this.contract.ownerId}).then(contract => {
        this.contract.moveDate = contract.moveDate
        this.contract.endDate = contract.endDate
        this.contract.smart = contract.smart
        this.contract.insurance = contract.insurance
        this.contract.gas = contract.gas
        this.contract.internet = contract.internet
        this.contract.price = contract.price
        this.contract.provision = contract.provision
        if (contract.landlord != null)
          this.contract.landlord = contract.landlord
        $this.setContractEdit()
        if (contract.status !== this.contract.status) {
          this.refresh();
        }
      })
    },
    submitDocumentsData() {
      let $this = this;
      this.updateFilesData({id: this.contract.id, contract: this.contract}).then(contract => {

        $this.setContractEdit()
        if (contract.status !== this.contract.status) {
          this.refresh();
        }
      })
    },
    submitStatus() {
      let $this = this;
      this.updateStatus({id: this.contract.id, status: this.newHistory}).then(history => {
        console.log(history)
        $this.contract.statusHistories = history.sort((a, b) => $this.$moment(b.time, 'YYYY-MM-DD HH:mm:ss').diff($this.$moment(a.time, 'YYYY-MM-DD HH:mm:ss')))
        $this.contract.status = $this.contract.statusHistories[0].status;
        $this.resetNewHistory();
      })
    },
    submitApproveStatus(e) {
      let $this = this;
      this.updateApproveStatus({id: this.contract.id, approveStatus: this.approve}).then(approve => {
        $this.contract.approve = approve
        $this.buildApproveData(this.contract);
      })
    },
    submitUpdateEditable() {
      this.updateEditableFields({id: this.contract.id, editableFields: this.editableFields});
    },
    requestSigningTheOffer() {
      this.$refs['sign-offer'].requestSignContract()
    },
    onHideFile(index) {
      let file = this.contract.files[index];
      file.visible = false;
      file.visibleForOwner = false;
      file.active = false;
      this.submitDocumentFile(index)
    },
    submitDocumentFile(index) {
      this.updateDocumentFile({id: this.contract.id, file: this.contract.files[index]});
    },
    submitSMS() {
      console.log("submitSMS ()")
      this.$root.$emit('crm::submitSms')
    },
    onSubmitted(server) {
      console.log("SMS -> onSubmitted()", server)
      this.dialogs.sms = false;
    },
    onDownloadFile(fileId) {
      let $this = this;
      this.downloadFile({fileId: fileId, clientId: $this.contract.clientId}).then(file => {
        $this.asyncDownloadFile(file)
      })
    },
    openClientAccount() {
      this.generateToken(this.client.id).then(res => {
        window.open(res.details.sitePath, '_blank');
      })
    }
  }
}
</script>

<style lang="scss">
.real-estate-rent-contract {
  .card-header {
    padding: 0;

    h4 {
      margin-bottom: 0;
      margin-left: 50px;
    }
  }

  i.icon {
    min-width: 20px;
  }

  .files-data {
    position: relative;
    height: 300px;

    > .upload-backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9999;

      .dropzone-custom-content {
        margin-top: 110px
      }
    }
  }
}

.max-lines-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.read-more {
  color: #c2aa8e; /* Change color as needed */
  cursor: pointer;
}

.read-more:hover {
  text-decoration: underline;
}

.hidden-text {
  display: none;
}
</style>
