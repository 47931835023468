<template>
  <b-modal v-model="dialog.active" size="lg" centered hide-footer no-close-on-backdrop body-class="p-0"
           @hidden="reset">
    <template #modal-title>{{ msg("Add Guest Feature") }}</template>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="4" align-self="center">
              <b-img :src="feature.imageUri" fluid thumbnail style="max-width: 240px; max-height: 300px"/>
            </b-col>
            <b-col cols="8">
              <b-row>
                <b-col cols="12">
                  <b-form-group :label="msg('Name')">
                    <b-input v-model="feature.name" placeholder="Name"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :label="msg('Description')">
                    <b-textarea v-model="feature.description" placeholder="Description"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="msg('Image Uri')">
            <b-input v-model="feature.imageUri" placeholder="Image Uri"/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="msg('Icon')">
            <b-input v-model="feature.icon" placeholder="Icon"/>
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="allBranches.length > 1">
          <b-form-group :label="msg('Branch Ids')">
            <v-select
                multiple
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-model="feature.branchIds"
                :options="allBranches"
                class="w-100"
                label="name"
                :reduce="(branch) => branch.id"
                :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-checkbox v-model="feature.amountAllowed">
              {{ msg('Amount Allowed') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-checkbox v-model="feature.autoChecked">
              {{ msg('Auto Checked') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-checkbox v-model="feature.dailyPayment">
              {{ msg('Daily Payment') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-checkbox v-model="feature.active">
              {{ msg('Active') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-btn block @click="reset" variant="outline-primary" v-disabled="loading">{{ msg('Cancel') }}</b-btn>
        </b-col>
        <b-col cols="6">
          <b-btn block @click="saveFeature" variant="primary">{{ msg('Save') }}</b-btn>
        </b-col>
      </b-row>
    </b-card-body>
  </b-modal>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

const getFeature = () => {
  return {
    name: null, description: null, icon: null, imageUri: null,
    amountAllowed: false, autoChecked: false,
    dailyPayment: false, branchIds: [], active: true
  }
}

export default {
  name: "GuestFeatureModal",
  data: () => ({
    filter: {},
    page: {amount: 10, currentPage: 1, total: 0},
    dialog: {
      active: false,
    },
    loading: false,
    feature: getFeature(),
  }),
  computed: {
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  methods: {
    ...mapActions('guestFeature',['insertGuestFeature', 'updateGuestFeature', 'fetchGuestFeatureById']),

    refresh() {

    },
    addNewFeature() {
      this.feature = getFeature();
      this.feature.branchIds = this.allBranches.map(branch => branch.id);
      this.dialog.active = true;
    },
    updateFeature(featureId) {
      console.log("updateFeature", featureId)
      let $this = this;
      $this.loading = true;
      this.fetchGuestFeatureById(featureId).then(data => {
        $this.feature = data.GuestFeature;
        $this.dialog.active = true;
      }).finally(() => {
        $this.loading = false;
      })
    },
    reset() {
      this.feature = getFeature();
    },
    saveFeature() {
      let $this = this;
      this.loading = true;
      let promise = null;
      if (this.feature.id == null) {
        promise = this.insertGuestFeature(this.feature);
      }else {
        promise = this.updateGuestFeature({id: this.feature.id, request: this.feature});
      }
      promise.then(() => {
        $this.dialog.active = false;
        $this.$emit('updated')
        $this.reset();
      }).finally(() => {
        $this.loading = false;
        $this.$emit('updated')
      })
    }
  }
}
</script>


<style scoped>

</style>
