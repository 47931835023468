<template>
  <div class="">
    <!--Loading hostaway export spinner-->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner">
        <b-spinner></b-spinner>
      </div>
    </div>
    <!--Filters-->
    <b-card no-body>

      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link class="mr-1" style="color: inherit" @click="resetFilters">
                <feather-icon icon="XIcon" size="16"/>
              </b-link>
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("Id") }}</label>
            <b-form-input
                v-model="filter.id"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="6" lg="3">

            <b-form-group :label="msg('Fixed')" label-for="realEstate-branch">
              <v-select
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  v-model="filter.fixed"
                  :options="fixedOptions"
                  class="w-100"
                  label="label"
                  :reduce="(branch) => branch.value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">

            <b-form-group :label="msg('Paid')" label-for="realEstate-branch">
              <v-select
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  v-model="filter.paid"
                  :options="paidOptions"
                  class="w-100"
                  label="label"
                  :reduce="(branch) => branch.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="3">

            <b-form-group :label="msg('Real estate')" label-for="realEstate-branch">
              <real-estate-picker multiple v-model="filter.realEstatesIds"/>
            </b-form-group>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>


    <!--Data-->
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Damages') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  @option:selected="refresh"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100,500]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- new -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <span class="mr-1"></span>
                <b-button variant="primary" @click="onNewDamage">
                  <span class="text-nowrap">{{ msg('New Damage') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <!--Table-->
        <b-overlay
            rounded="sm">
          <div v-handy-scroll class="table-responsive">
            <b-table
                ref="realEstate-table"
                striped
                hover
                v-bind:items="damages"
                v-bind:fields="fields"
                :busy="loading"
                @sort-changed="onSort"
                @row-clicked="onItemSelected"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mx-1"></b-spinner>
                  <strong>{{ msg('Loading') }}...</strong>
                </div>
              </template>

              <template #cell(buildingId)="data">
                <b-link style="font-weight: bold" v-if="data.value!=null && data.value!==''"
                        @click="routeToBuilding(data.value)" variant="danger">
                  {{ buildings[data.value].address }}
                </b-link>
              </template>

              <template #cell(realEstateId)="data">
                <b-link style="font-weight: bold" v-if="data.value!=null && data.value!==''"
                        @click="routeToProperty(data.value)" variant="danger">
                  {{ realEstates[data.value].name }}
                </b-link>
              </template>

              <template #cell(contractId)="data">

                <b-link style="color:rgba(222,97,44,0.76);" @click="routeToContract(data.value)"
                        class="event-details-description badge">{{ $t('redirecttocontract') }}
                </b-link>

              </template>

              <template #cell(damageCost)="data">
                {{ convertToEURO(data.value) }}
              </template>
              <template #cell(missingResources)="data">
                <b-badge v-if="data.item.missingResources==null"  variant="danger">
                  {{ msg('No') }}
                </b-badge>
                <b-badge v-else variant="success">
                  {{ msg('Yes') }}
                </b-badge>
              </template>
              <template #cell(paidByClient)="data">
                <b-badge v-if="data.value==null || data.value== false" variant="danger">
                  {{ msg('No') }}
                </b-badge>
                <b-badge v-else variant="success">
                  {{ msg('Yes') }}
                </b-badge>
              </template>
              <template #cell(isClientFault)="data">
                <b-badge v-if="data.value==null || data.value== false" variant="danger">
                  {{ msg('No') }}
                </b-badge>
                <b-badge v-else variant="success">
                  {{ msg('Yes') }}
                </b-badge>
              </template>
              <template #cell(paid)="data">
                <b-badge v-if="data.value==null || data.value== false" variant="danger">
                  {{ msg('No') }}
                </b-badge>
                <b-badge v-else variant="success">
                  {{ msg('Yes') }}
                </b-badge>
              </template>

              <template #cell(damageFixed)="data">
                <b-badge v-if="data.value==null || data.value== false" variant="danger">
                  {{ msg('No') }}
                </b-badge>
                <b-badge v-else variant="success">
                  {{ msg('Yes') }}
                </b-badge>
              </template>

              <template #cell(clientId)="data">
                <div v-if="clients[data.value] != null">
                  <b-badge variant="warning" @click="onClientSelect(clients[data.value].id)">{{
                      clients[data.value].firstname
                    }}
                    {{ clients[data.value].lastname }}
                  </b-badge>
                </div>
                <div v-else>
                  <b-badge variant="warning">{{ msg('Anonymous') }}</b-badge>
                </div>
              </template>


              <template #cell(reportedTime)="data">
                <span v-if="data.value!=null && data.value!==''" variant="warning">
                  {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
                </span>

              </template>

            </b-table>
          </div>

          <!--Table Paginator-->
          <div class="text-center w-100">
            <div class="d-inline-block">
              <b-pagination
                  v-model="page.currentPage"
                  :total-rows="page.total"
                  :per-page="page.amount"
                  first-number
                  last-number
                  @input="refresh"
              />
            </div>
            <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
          </div>
        </b-overlay>
      </b-card-body>
    </b-card>
    <damage-modal ref="damageModal" @updated="refresh"/>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import mixinFilter from "@/mixins/mixinFilter";
import {BOverlay} from "bootstrap-vue";
import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";
import DamageModal from "@/components/modals/damage/damageModal.vue";


export default {
  name: 'Damages',
  mixins: [mixinFilter],
  components: {
    DamageModal,
    RealEstatePicker,
    BOverlay,
  },
  data() {
    return {
      loading: true,
      amount: 10,
      buid: null,
      isLoading: false,
      clients: {},
      damages: [],
      realEstates: {},
      buildings: {},
      fields: [],
      filter: {
        fixed: null,
        paid: null,
        id: null,
      },

      sort: {
        by: '',
        desc: true,
        direction: 'asc'
      },
      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },

      //Listeners
      listeners: {
        refresh: () => {
        }
      }
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    paidOptions() {
      let $this = this;
      return [
        {label: $this.msg('All'), value: null},
        {label: $this.msg('Paid'), value: true},
        {label: $this.msg('Not Paid'), value: false}
      ];
    },
    fixedOptions() {
      let $this = this;
      return [
        {label: $this.msg('All'), value: null},
        {label: $this.msg('Fixed'), value: true},
        {label: $this.msg('Not Fixed'), value: false}
      ];
    }
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
    this.$root.$on('crm::lang-updated', this.setFields)

  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
    this.$root.$off('crm::lang-updated', this.setFields)
  },
  methods: {
    ...mapActions('damage', ['getAllDamagesCustom']),
    ...mapActions('clients', ['getClients']),
    routeToContract(eventId) {
      window.open(`/real_estate/contracts/rent/${eventId}`, '_blank');
    },
    routeToBuilding(eventId) {
      window.open(`/building/edit/${eventId}`, '_blank');
    },
    routeToProperty(eventId) {
      window.open(`/real_estate/edit/${eventId}`, '_blank');
    },
    retrieveClients(damages) {
      console.log("retrieveClients start...")

      let $this = this
      let ids = damages.map((damage) => damage.clientId)
      this.getClients({
        page: 1,
        amountPerPage: -1,
        filter: {ids}
      }).then((clientData) => {


        $this.clients = clientData.clients.toObject('id')
        $this.loading = false
        console.log("retrieveClients end...")

      })
    },
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', clientId)
    },
    setFields() {
      let $this = this
      this.fields = [
        {
          key: 'clientId',
          label: $this.msg('Client'),
          sortable: true,
          active: true,
        },
        {
          key: 'buildingId',
          label: $this.msg('Building'),
          sortable: true,
          active: true,
        },

        {
          key: 'realEstateId',
          label: $this.msg('Property'),
          sortable: false,
          active: true,
        },

        {
          key: 'contractId',
          label: $this.msg('Contract'),
          sortable: true,
          active: true,
        },
        {
          key: 'damageDescription',
          label: $this.msg('Description'),
          sortable: true,
          active: true,
        },
        {
          key: 'damageCost',
          label: $this.msg('Cost'),
          sortable: false,
          active: true,
        },
        {
          key: 'paid',
          label: $this.msg('Paid'),
          sortable: true,
          active: true,
        }, {
          key: 'isClientFault',
          label: $this.msg('Client fault'),
          sortable: true,
          active: true,
        }, {
          key: 'paidByClient',
          label: $this.msg('Paid by client'),
          sortable: true,
          active: true,
        },

        {
          key: 'damageFixed',
          label: $this.msg('Fixed'),
          sortable: false,
          active: true,
        },
        {
          key: 'damageFixedBy',
          label: $this.msg('Fixed by'),
          sortable: false,
          active: true,
        },
        {
          key: 'missingResources',
          label: $this.msg('Missing resources'),
          sortable: false,
          active: true,
        },
        {
          key: 'reportedBy',
          label: $this.msg('reportedBy'),
          sortable: true
        },
        {
          key: 'reportedTime',
          label: $this.msg('Reported at'),
          sortable: true,
          active: true,
        },


      ];

    },
    onItemSelected(damage) {
      this.$refs.damageModal.showById(damage.id);
    },
    refresh() {
      let $this = this;
      /* if (this.buildingId == null && this.realEstatesIds == null) {
         this.damages = [];
         return;
       }*/
      this.loading = true;
      this.getAllDamagesCustom({
        ...$this.pagination, filter: $this.filter, sortcolumn: $this.sort.by,
        sortby: $this.sort.direction,
      }).then(ans => {
        this.page = {
          amount: ans['Amount'],
          total: ans['Total'],
          currentPage: ans['Page']
        };
        $this.sort = {
          by: ans["sortColumn"],
          direction: ans["sortOrder"]
        }
        $this.damages = ans["Damages"];
        $this.buildings = ans["Buildings"];
        $this.realEstates = ans["RealEstates"];
        $this.retrieveClients(this.damages);

        $this.loading = false;
      }, e => {
      }).finally(() => {
        $this.loading = false;
      });
    },


    onRealEstateSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.realEstate = true
      }
    },

    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },

  }
}
</script>

<style lang="scss">
.features-group .btn-group-toggle {
  width: 100%;

  i {
    font-size: 26px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #f69c55;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust the z-index as needed */
}

.loading-spinner {
  text-align: center;
}

.platform-icon {
  width: 40px;
  height: 40px;
}

</style>
