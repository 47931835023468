import Vue from 'vue'
import Vuex, {mapGetters} from 'vuex'
import uiCoreStore from "./store/ui-core/index"
import {userModule} from "./store/Core/user.module";
import dataModule from "./store/Core/data.module";
import {themeModule} from "./store/Core/theme.module";
import {clientsModule} from "./store/Core/clients.module";
import notificationModule from "./store/ui-core/notification.module";
import {usersModule} from "./store/Core/users.module";
import {organizationsModule} from "./store/Core/organizations.module";
import {branchesModule} from "./store/Core/branches.module";
import {BranchSettingsModule} from "./store/Core/BranchSettings.module";
import {phoneRecordsModule} from "./store/Modules/Phone/phone.module";
import {calendarModule} from "./store/Core/calendar.module";
import {roleModule} from "./store/Core/role.module";
import {permissionsModule} from "./store/Core/permissions.module";
import {moduleModule} from "./store/Core/module.module";
import {translatorModule} from "./store/Core/translator.module";
import {mailEventsModule} from "./store/Core/mailevents.module";
import {notesModule} from "./store/Modules/Note/notes.module";
import {affiliateModule} from "./store/Core/affiliate.module";
import {documentModule} from "./store/Core/document.module";
import {UIComponentsModule} from "./store/Modules/UI/UIComponents.module";
import {googleApiModule} from "./store/Modules/Api/GoogleApi.Module";
import {deviceModule} from "./store/Modules/Device/device.module";
import {AdsVideosModule} from "./store/Modules/Ads/videos.module";
import {faceDetectionModule} from "./store/Modules/FaceDetection/facedetection.module";
import {campaignModule} from "./store/Modules/Campaign/campaign.module";
import {monetaryModule} from "@/store/Modules/Monetary/monetary.module";
import {productModule} from "@/store/Modules/Product/product.module";
import {categoryModule} from "@/store/Modules/Product/category.module";
import {couponCampaignsModule} from "@/store/Modules/Coupon/coupon.module";
import {realEstateModule} from "@/store/Modules/RealEstate/realEstate.module";
import {realEstateBookingModule} from "@/store/Modules/RealEstate/realEstateBooking.module";
import {orderModule} from "@/store/Modules/Order/orders.module";
import {smsModule} from "@/store/Modules/Sms/sms.module";
import {nukiSmartLockModule} from "@/store/Modules/Nuki/nukiSmartLock.module";
import {realEstatesContractsModule} from "@/store/Modules/RealEstate/realEstatesContracts.module";
import {realEstatesContractsStatsModule} from "@/store/Modules/RealEstate/realEstatesContractsStats.module";
import {realEstatesContractsAlertsModule} from "@/store/Modules/RealEstate/realEstatesContractsAlerts.module";
import {voucherModule} from "@/store/Modules/Product/voucher.module";
import {buildingModule} from "@/store/Modules/RealEstate/building.module";
import {ksvModule} from "@/store/Modules/RealEstate/ksv.module";
import {ticketModule} from "@/store/Modules/RealEstate/ticket.module";
import {vipClubModule} from "@/store/Modules/RealEstate/vipClub.module";
import {bankModule} from "@/store/Modules/Bank/bank.module";
import {refinanceModule} from "@/store/Modules/RealEstate/refinance.module";
import {realEstatesBuyContractsModule} from "@/store/Modules/RealEstate/realEstatesBuyContracts.module";
import {contractTemplateModule} from "@/store/Modules/RealEstate/contractTemplate.module";
import {chatModule} from "@/store/Modules/chat/chat.module";
import {whitelabelModule} from "@/store/Modules/Whitelabel/whitelabel.module";
import {hostawayModule} from "@/store/Modules/Hostaway/hostaway.module";
import {whatsappModule} from "@/store/Modules/Whatsapp/whatsapp.module";
import {messageModule} from "@/store/Modules/Whatsapp/message.module";
import {kanbanModule} from "@/store/Modules/RealEstate/kanban.module";
import {pricelabsModule} from "@/store/Modules/RealEstate/pricelabs.module";
import {realEstatePriceLabsModule} from "@/store/Modules/RealEstate/RealEstatesPriceLabs";
import {taxModule} from "@/store/Modules/Product/tax.module";
import {cleaningModule} from "@/store/Modules/Cleaning/cleaning.module";
import {damagesModule} from "@/store/Modules/Damage/damage.module";
import {messageTemplateModule} from "@/store/Modules/RealEstate/messageTemplate.module";
import {priorityClientModule} from "@/store/Modules/Client/priorityClient.module";
import {routerModule} from "@/store/Modules/RealEstate/router.module";
import {depositModule} from "@/store/Modules/RealEstate/deposit.module";
import {electricityModule} from "@/store/Modules/RealEstate/electricity.module";
import {gasModule} from "@/store/Modules/RealEstate/gas.module";
import {guestFeatureModule} from "@/store/Modules/features/guestFeature.module";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        ...uiCoreStore,
        user: userModule,
        data: dataModule,
        clients: clientsModule,
        organizations: organizationsModule,
        branches: branchesModule,
        BranchSettings: BranchSettingsModule,
        mailEvent: mailEventsModule,
        monetary: monetaryModule,
        users: usersModule,
        phone: phoneRecordsModule,
        theme: themeModule,
        notifications: notificationModule,
        calendar: calendarModule,
        permissions: permissionsModule,
        role: roleModule,
        modules: moduleModule,
        translator: translatorModule,
        notes: notesModule,
        affiliate: affiliateModule,
        document: documentModule,
        googleApi: googleApiModule,
        ui_components: UIComponentsModule,
        device: deviceModule,
        ads_videos:AdsVideosModule,
        faceDetection:faceDetectionModule,
        campaign:campaignModule,
        product:productModule,
        realEstate:realEstateModule,
        realEstatePriceLabs:realEstatePriceLabsModule,
        building:buildingModule,
        ksv:ksvModule,
        ticket:ticketModule,
        realEstateBooking:realEstateBookingModule,
        category:categoryModule,
        couponCampaign:couponCampaignsModule,
        order:orderModule,
        sms:smsModule,
        nuki:nukiSmartLockModule,
        realEstateContracts:realEstatesContractsModule,
        realEstateContractsStats:realEstatesContractsStatsModule,
        realEstateBuyContracts:realEstatesBuyContractsModule,
        voucher:voucherModule,
        realEstatesContractsAlerts:realEstatesContractsAlertsModule,
        vipClub: vipClubModule,
        bank:bankModule,
        priorityClient: priorityClientModule,
        refinance:refinanceModule,
        contractTemplate:contractTemplateModule,
        chat:chatModule,
        whitelabel:whitelabelModule,
        hostaway: hostawayModule,
        whatsapp: whatsappModule,
        messages:messageModule,
        kanban:kanbanModule,
        pricelabs:pricelabsModule,
        tax:taxModule,
        cleaning: cleaningModule,
        damage:damagesModule,
        router:routerModule,
        gas:gasModule,
        electricity:electricityModule,
        deposit:depositModule,
        messageTemplate:messageTemplateModule,
        guestFeature:guestFeatureModule
    }
});

//AUTO INSERT MSG
Vue.mixin(
    {
        computed: {
            ...mapGetters('translator', ['msg', 'currentFormattedLang']), ...mapGetters('appConfig', ['isRTL']),
            deviceLang() {
                try {
                    if (navigator.languages != null)
                        return navigator.languages[0];
                    return navigator.language;
                } catch (e) {
                    console.warn('Error getting language', e);
                    return 'de';
                }
            },
            deviceBasicLang() {
                try {
                    if (navigator.languages == null)
                        return "de";
                    if(navigator.language.includes('-'))
                        return navigator.language.split('-')[0];
                    return navigator.language;
                } catch (e) {
                    console.warn('Error getting language', e);
                    return 'de';
                }
            }
        },
        methods: {
            getUrl(src){
                return window.config.apiUrl + src;
            },
            addNotAssign(arr) {
                let $this = this;
                if (typeof arr === "object") arr = Object.values(arr);
                return [{
                    id: -1,
                    status: $this.msg('Not assign'),
                    firstname: $this.msg('Not assign'),
                    lastname: ''
                }, ...arr]
            },
            mergeSrcUrl(base, attachment) {
                if (attachment == null)
                    return null;
                if (attachment.attachmentUrl.startsWith("http"))
                    return attachment.attachmentUrl;
                return base + attachment.attachmentUrl;
            },
            mergeSrcUrlCustom(attachment) {
                if (attachment == null)
                    return null;
                if (attachment.attachmentUrl.startsWith("http"))
                    return attachment.attachmentUrl;
                return 'https://treasurehome.at' + attachment.attachmentUrl;
            },
            scrollToElement(ref) {
                const el = this.$refs[ref];

                if (el) {
                    // Use el.scrollIntoView() to instantly scroll to the element
                    el.scrollIntoView({behavior: 'smooth', block: 'center'});
                }
            },
            asyncDownloadFile(file) {
                let link = document.createElement("a");
                console.log("getDocument", file)
                // If you don't know the name or want to use
                // the webserver default set name = ''
                link.setAttribute('download', file.fileName);
                link.href = URL.createObjectURL(file.data);
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            getImgUrlFromServer(url){
                const options = {headers: {'Authorization': `Bearer ${localStorage.Bearer}`},};
                return fetch(window.config.apiUrl + url, options)
                    .then(res => res.blob())
                    .then(blob => URL.createObjectURL(blob));
            }
        },
    });
