<template>
  <div class="realEstate-page" style="min-height: 80vh">
    <div v-if="loaded === true">
      <!-- RealEstate statistics -->

      <b-row v-if="building!=null&&  (building.buildingExpenses==null ||  building.buildingExpenses.length <1)" class="realEstate-statistics">
        <b-container>
          <b-alert show variant="danger" class="d-flex justify-content-center align-items-center">
            <span style="font-size: 20px;">Please be aware that no expenses have been added to this building, your numbers are not correct.</span>
          </b-alert>
        </b-container>

        <!--        <b-col lg="4" sm="6">
                  <statistic-card-horizontal icon="UserIcon" :statistic="renterCount" statistic-title="Mieter"/>
                </b-col>
                <b-col lg="4" sm="6">
                  <statistic-card-horizontal icon="UserPlusIcon" color="success" :statistic="ownerCount"
                                             statistic-title="Eigentümer"/>
                </b-col>
                <b-col lg="4" sm="6">
                  <statistic-card-horizontal icon-type="fontawesome" icon="fas fa-trophy"
                                             color="info"
                                             :statistic="convertToEURO((replaceIfNotNull(building.sellWorthIntern)-replaceIfNotNull(building.buyPrice)).toFixed(2))"
                                             statistic-title="Gewinnchance"/>
                </b-col>-->
<!--        <b-col lg="6" sm="6">
          <statistic-card-horizontal icon="CreditCardIcon" color="success"
                                     :statistic="convertToEURO((replaceIfNotNull(building.rentPa)/12).toFixed(2))"
                                     statistic-title="Mieteinnahmen Monatlich"/>
        </b-col>
        <b-col lg="6" sm="6">
          <statistic-card-horizontal icon="CreditCardIcon" color="danger"
                                     :statistic="convertToEURO((replaceIfNotNull(building.yearlyCost)/12).toFixed(2))"
                                     statistic-title="Kosten Monatlich"/>
        </b-col>-->
      </b-row>
      <b-row class="mb-2" v-if="building!=null && canOperateBuilding">

      </b-row>
      <b-card>
        <b-card-title>
          <h2 class="mb-1 font-weight-bold">
            {{ msg('Building') }}: {{ building.address }}
          </h2>
        </b-card-title>
      <b-tabs content-class="mt-3" class="custom-tabs">
        <!-- Management Details Tab -->
        <b-tab title="Management Details" active>
          <b-row>
            <b-col cols="12" class="col-12">
              <b-card no-body class="card-stats" border-variant="warning">
                <b-card-body>
                  <div>
                    <div class="d-flex">
                      <feather-icon icon="PackageIcon" size="19"/>
                      <h4 class="mb-0 ml-50">
                        {{ msg('Building details').toUpperCase() }}
                      </h4>
                    </div>
                    <building-management v-model="building" @change="() => buildingUpdated = true"/>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <!-- Real Estate Details -->

          <b-row>
            <b-col cols="12" class="col-12">
              <b-card no-body class="card-stats" border-variant="warning">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2 w-100">
                    <b-row>
                      <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <feather-icon icon="UsersIcon" size="18"/>
                        <span class="align-middle ml-50">{{ msg('Real estates') }}</span>
                      </b-col>
                      <b-col cols="12" md="6">
                        <real-estate-picker noBuildingYet v-model="newPropertyId"/>
                      </b-col>
                      <b-col cols="12" md="2">
                        <div class="d-flex align-items-center justify-content-end">
                          <button :disabled="newPropertyId == null" id="addProperty" @click="addPropertyToBuilding"
                                  size="sm" class="btn btn-primary">
                            <feather-icon icon="UserPlusIcon" size="12"/>
                            {{ msg('Add Property') }}
                          </button>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-title>
                </b-card-header>
                <building-properties @delete="onDelete" :historyMaxHeight="500" v-bind:value="building"/>
              </b-card>
            </b-col>
          </b-row>
          <!--pictures-->
          <b-row>
            <b-col cols="12">
              <b-card no-body class="card-stats" border-variant="warning">
                <b-card-header>
                  <b-row no-gutters class="w-100">
                    <b-col cols="6">
                      <div class="d-flex">
                        <i class="far fa-images"></i>
                        <h4 class="mb-0 ml-50">
                          {{ msg('Pictures').toUpperCase() }}
                        </h4>
                      </div>
                    </b-col>
                    <b-col cols="6" class="text-right">
                      <div>
                        <b-button variant="outline-primary" class="btn-icon" id="realEstate-upload-image">
                          <feather-icon icon="UploadIcon"/>
                        </b-button>
                        <div>
                          <b-popover target="realEstate-upload-image" size="xl">
                            <vue-dropzone id="video-dropzone"
                                          :options="dropzoneImagesOptions"
                                          :useCustomSlot="true"
                                          :duplicateCheck="true"
                                          @vdropzone-complete="uploadImageComplete"
                                          @vdropzone-success="uploadImageSuccess">
                              <div class="dropzone-custom-content">
                                <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                                <div class="subtitle">...or click to select a file from your computer</div>
                              </div>
                            </vue-dropzone>
                          </b-popover>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="6"></b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <realEstate-image-swiper v-model="building.attachments" @change="buildingUpdated = true"/>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <!--features-->
          <b-card no-body class="card-stats" border-variant="warning">
            <b-card-header>
              <b-row no-gutters class="w-100">
                <b-col cols="6">
                  <div class="d-flex">
                    <i class="fa-solid fa-bell-concierge"></i>
                    <h4 class="mb-0 ml-50">
                      {{ msg('Features').toUpperCase() }}
                    </h4>
                  </div>
                </b-col>
                <b-col cols="6" class="text-right">
                  <b-button variant="outline-primary" class="btn-icon" @click="()=>$refs.realEstateFeature.addNewFeature()">
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <real-estate-feature ref="realEstateFeature" v-model="building.features" v-bind:buid="building.buid"/>
            </b-card-body>
          </b-card>
          <!--files-->
          <b-row>
            <b-col cols="12">
              <b-card style="height: auto;" border-variant="warning">
                <div>
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                      <feather-icon icon="FileIcon" size="19"/>
                      <h4 class="mb-0 ml-50">
                        {{ msg('Files').toUpperCase() }}
                      </h4>
                    </div>
                    <div>
                      <b-button size="sm" v-bind:variant="uploadFile ? 'warning' : 'outline-warning'"
                                @click="() => uploadFile = !uploadFile">
                        <i class="fas fa-upload"></i> {{ msg("Upload file") }}
                      </b-button>
                    </div>
                  </div>
                  <div class="mb-2">
                    <b-alert show class="p-1" variant="secondary"><i style="color: red"
                                                                     class="fas fa-exclamation-circle"></i>
                      {{ msg('Mandatory documents') }}: <span style="font-weight: bold">-Kaufvertrag -Finanzierung -Bauakte -Hausverwaltung -Versicherung -Fotos -Marketing -Grundrissplan -Gutachten -Kreditvertrag -Bauvorhaben -Sonstiges</span>
                    </b-alert>
                  </div>
                  <div>
                    <b-col>
                      <b-form-group
                          :label="msg('Enter here the email address and choose the documents down below (make sure you havent selected more than 25MB)')"
                          label-for="email">
                        <b-input-group>
                          <b-form-input id="email" v-model="emailAddress"/>
                          <b-input-group-append>
                            <b-button
                                :disabled="emailAddress == null || emailAddress.length === 0 || selectedFileIds.length < 1"
                                variant="outline-primary" v-b-tooltip :title="msg('Send Email')"
                                @click="sendAllFilesByMail">
                              <i class="fa fa-mail-bulk mr-1"></i> EMAIL
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </div>
                  <div class="files-data">
                    <div class="upload-backdrop" v-show="uploadFile === true">
                      <vue-dropzone id="contract-upload" class="h-100" :options="dropzoneOptions" :useCustomSlot="true"
                                    :duplicateCheck="true" @vdropzone-complete="uploadComplete"
                                    @vdropzone-success="uploadSuccess">
                        <div class="dropzone-custom-content">
                          <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                          <div class="subtitle">...or click to select a file from your computer</div>
                        </div>
                      </vue-dropzone>
                    </div>
                    <b-table-simple outlined>
                      <b-tr style="background-color: rgba(229,16,48,0.77)!important;">
                        <b-th class="text-center" style="width: 10%">{{ msg('Select') }}</b-th>
                        <b-th class="text-center" style="width: 40%">{{ msg('File') }}</b-th>
                        <b-th class="text-center" style="width: 20%">{{ msg('Tag') }}</b-th>
                        <b-th class="text-center" style="width: 20%">{{ msg('Note') }}</b-th>
                        <b-th class="text-center" style="width: 15%">{{ msg('Operations') }}</b-th>
                      </b-tr>
                    </b-table-simple>
                    <perfect-scrollbar class="position-relative" style="height: auto">
                      <b-table-simple outlined>
                        <b-tr v-for="(file, index) in (building.files || [])" v-bind:key="file.id"
                              style="white-space: nowrap; text-overflow: ellipsis" v-show="file.active">
                          <b-td class="text-center" style="width: 10%">
                            <b-form-checkbox v-model="selectedFileIds" :value="file.id"/>
                          </b-td>
                          <b-td style="width: 30%">
                            <i v-bind:class="iconByExt(file.filename)" class="mx-1"
                               style="font-size: 30px; font-weight: bold"></i>{{ file.filename }} <span
                              v-if="file.size != null" style="color: red!important;">{{ getFileSize(file.size) }}</span>
                          </b-td>
                          <b-td class="text-center" style="width: 20%">
                            <v-select :options="fileTags" :reduce="(tag) => tag.value" label="title" v-model="file.tag"
                                      append-to-body @input="() => submitDocumentFile(index)">
                              <template v-slot:option="option">
                                <i class="d-inline-block text-left" style="width: 30px" :class="option.icon"></i>
                                <span>{{ option.title }}</span>
                              </template>
                            </v-select>
                          </b-td>
                          <b-td class="text-center" style="width: 20%">
                            <b-form-textarea v-model="file.notes" @change="() => submitDocumentFile(index)"
                                             size="sm"></b-form-textarea>
                          </b-td>
                          <b-td class="text-center" style="width: 15%">
                            <b-link class="mr-2"><i class="icon fas fa-download w-auto"
                                                    @click="() => onDownloadFile(file.id)"></i></b-link>
                            <b-link><i class="icon fas fa-eye w-auto" @click="() => onPreviewFile(file.id)"></i>
                            </b-link>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </perfect-scrollbar>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Expenses Details Tab -->
        <b-tab title="Expenses Details" v-if="building.propertyIds != null && building.propertyIds.length > 0">
          <b-row>
            <b-col cols="12" class="col-12">
              <b-card no-body class="card-stats" border-variant="warning">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2 w-100">
                    <b-row>
                      <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <feather-icon icon="CreditCardIcon" size="18"/>
                        <span class="align-middle ml-50">{{ msg('Expenses') }}</span>
                      </b-col>
                    </b-row>
                  </b-card-title>
                </b-card-header>
                <building-expenses-table :value="building"/>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Invoice Details Tab -->
        <b-tab title="Invoice Details">
          <b-card no-body class="card-stats" border-variant="warning">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2 w-100">
                <b-row>
                  <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <feather-icon icon="CreditCardIcon" size="18"/>
                    <span class="align-middle ml-50">{{ msg('Invoices') }}</span>
                  </b-col>
                </b-row>
              </b-card-title>
            </b-card-header>
            <building-invoice-table :value="building" @invoice="loadBuilding"/>
          </b-card>

          <b-card no-body class="card-stats" border-variant="warning">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2 w-100">
                <b-row>
                  <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <feather-icon icon="CreditCardIcon" size="18"/>
                    <span class="align-middle ml-50">{{ msg('Internal payments') }}</span>
                  </b-col>
                </b-row>
              </b-card-title>
            </b-card-header>
            <building-internal-payment-table :value="building" @invoice="loadBuilding"/>
          </b-card>
        </b-tab>

        <!-- RealEstate Description -->
        <b-tab :title="msg('Description')">
          <b-col cols="12" class="col-12">

                <div>
                  <!-- Header: Personal Info -->
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                      {{ msg('Description') }}
                    </h4>
                  </div>
                  <building-text v-model="building" @change="()=>buildingUpdated=true"/>
                </div>

          </b-col>
        </b-tab>
        <!-- Bank Details Tab -->
        <b-tab title="Bank Details" v-if="canOperateBuilding">
          <b-row>
            <b-col cols="12" class="col-12">
              <bankdetails @delete="onDelete" :historyMaxHeight="280" :value="building"/>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Renters/Owners Tab -->
        <b-tab :title="msg('Regular customers')">
          <b-row>
            <b-col cols="12" class="col-12">
              <b-card no-body class="card-stats" border-variant="warning">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2 w-100">
                    <b-row>
                      <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <feather-icon icon="UsersIcon" size="18"/>
                        <span class="align-middle ml-50">{{ msg('Regular customers')+'( >2 contracts)' }}</span>
                      </b-col>
                    </b-row>
                  </b-card-title>
                </b-card-header>
                <client-table :filter="clientFilter" @clientSelect="onClientSelect" v-bind:amountPerPage="50"
                              :deleteStatus="0"/>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Damages Tab-->
        <b-tab :title="msg('Damages')">
          <b-col cols="12" class="col-12">
            <b-card no-body class="card-stats" border-variant="warning">
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2 w-100">
                  <b-row>
                    <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                      <i class="fa-solid fa-broom"></i>
                      <span class="align-middle ml-50">{{ msg('Damages') }}</span>
                    </b-col>
                    <b-col class="text-right">
                      <b-link style="color: inherit" @click="$refs.damage.refresh()">
                        <feather-icon icon="RefreshCcwIcon" size="16"/>
                      </b-link>
                    </b-col>
                  </b-row>
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <damage-panel ref="damage" :buildingId="building.id"/>
              </b-card-body>
            </b-card>
          </b-col>
        </b-tab>

        <!-- Damages Tab-->
        <b-tab :title="msg('Todo List')">
          <b-col cols="12" class="col-12">
            <b-card no-body class="card-stats" border-variant="warning">
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2 w-100">
                  <b-row>
                    <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                      <i class="fa-solid fa-broom"></i>
                      <span class="align-middle ml-50">{{ msg('Todo List') }}</span>
                    </b-col>
                    <b-col class="text-right">
                      <b-link style="color: inherit" @click="$refs.damage.refresh()">
                        <feather-icon icon="RefreshCcwIcon" size="16"/>
                      </b-link>
                    </b-col>
                  </b-row>
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <building-todo v-model="building"/>
              </b-card-body>
            </b-card>
          </b-col>
        </b-tab>

        <!-- Tracker History Tab -->
        <b-tab title="Tracker History">
          <b-row>
            <b-col cols="12">
              <b-card border-variant="warning">
                <div>
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                      <feather-icon icon="FileIcon" size="19"/>
                      <h4 class="mb-0 ml-50">
                        {{ msg('History').toUpperCase() }} / {{ msg('Status').toUpperCase() }}
                      </h4>
                    </div>
                  </div>
                  <div class="text-center px-2">
                    <div class="d-inline-block m-0">
                      <b-button v-b-toggle.collapse-1 variant="primary" class="mx-1">
                        {{ msg("Status history timeline") }}
                      </b-button>
                    </div>
                    <h5 class="d-inline-block my-0 mx-2">{{ msg("Status") }}:</h5>
                    <div class="d-inline-block m-0">
                      <v-select style="min-width: 300px; text-align: center" :dir="isRTL ? 'rtl' : 'ltr'"
                                :clearable="false" v-model="building.status" label="text" :options="allStatus"
                                :reduce="(status) => status.value" @option:selected="buildingUpdated = true"
                                class="w-100"/>
                    </div>
                  </div>
                  <div>
                    <b-collapse id="collapse-1" class="mt-2">
                      <b-card v-if="building.histories != null && building.histories.length > 0">
                        <app-timeline v-for="(statusHistory, index) in building.histories" v-bind:key="index">
                          <app-timeline-item class="mb-2"
                                             :title="statusHistory.status != null ? statusHistory.status.replace('_', ' ') : null"
                                             :subtitle="'Source: ' + statusHistory.source + (statusHistory.userId > 0 ? (', User: ' + getUserFullName(statusHistory.userId)) : '')"
                                             :time="statusHistory.time | moment('DD/MM/YY HH:mm')" :variant="null"
                                             :tracker-type="statusHistory.trackerType.replaceAll('_', ' ')"
                                             :tracker-value="statusHistory.value"/>
                        </app-timeline>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
      </b-card>
      <div class="update-realEstate" v-bind:class="{'active':(buildingUpdated===true)}">
        <b-button variant="primary" class="mx-1" @click="updateBuilding">{{ msg("Save") }}</b-button>
        <b-button variant="warning" @click="loadBuilding">{{ msg("Cancel") }}</b-button>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-items-center" style="min-height: 600px">
        <div class="d-inline-block w-100 text-center">
          <b-spinner class="mx-1" variant="primary"/>
          <strong>{{ msg('Loading') }}...</strong>
        </div>
      </div>
    </div>

    <b-modal :id="infoModal.id" :title="msg('Confirmation')" @ok="createRefinance"
             ok-variant="success" @hide="resetInfoModal" :cancel-title="msg('cancel')">
      <p class="my-4">{{ msg('refinancequestion') }}</p>
    </b-modal>
  </div>

</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import BuildingManagement from '@/views/realEstate/building/buildingManagement'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import clientTable from "@/components/components/clientTable";
import bankdetails from "@/components/panels/bank/bankdetails";
import vue2Dropzone from "vue2-dropzone";
import ClientPicker from "@/components/components/picker/clientPicker";
import buildingProperties from "@/components/panels/realEstates/buildingProperties";
import RealEstatePicker from "@/components/components/picker/realEstatesPicker";
import RealEstateImageSwiper from "@/components/panels/realEstates/realEstateImageSwiper";
import AppTimeline from "@core/components/app-timeline/AppTimeline";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem";
import BuildingUserManagement from "@/views/realEstate/building/buildingUserManagement";
import buildingExpensesTable from "@/views/realEstate/building/buildingExpensesTable";
import BuildingInvoiceTable from "@/views/realEstate/building/BuildingInvoiceTable.vue";
import {BuildingModel} from "@/model/RealEstate/BuildingModel";
import BuildingText from "@/views/realEstate/building/buildingTexts";
import BuildingInternalPaymentTable from "@/views/realEstate/building/BuildingInternalPaymentTable.vue";
import DamagePanel from "@/components/panels/damages/DamagePanel.vue";
import BuildingTodo from "@/components/panels/realEstates/buildingTodo.vue";
import RealEstateFeature from "@/components/panels/realEstates/realEstateFeature.vue";

export default {
  name: 'editBuilding',
  components: {
    RealEstateFeature,
    BuildingTodo,
    DamagePanel,
    BuildingText,
    BuildingInvoiceTable,
    BuildingInternalPaymentTable,
    clientTable,
    bankdetails,
    AppTimeline,
    AppTimelineItem,
    BuildingManagement,
    // eslint-disable-next-line vue/no-unused-components
    BuildingUserManagement,
    // eslint-disable-next-line vue/no-unused-components
    StatisticCardHorizontal,
    vueDropzone: vue2Dropzone,
    RealEstateImageSwiper,
    // eslint-disable-next-line vue/no-unused-components
    ClientPicker,
    buildingProperties,
    RealEstatePicker,
    buildingExpensesTable
  },

  data() {
    return {
      testFile: null,
      loaded: false,
      error: null,
      infoModal: {
        id: 'info-modal',
        value: null,
        title: '',
        content: ''
      },
      building: {
        name: '',

      },
      emailAddress: null,
      selectedFileIds: [],
      newClientId: null,
      newPropertyId: null,
      clientFilter: {
        creationDateFrom: null,
        creationDateUtil: null,
        assignationDateFrom: null,
        assignationDateUtil: null,
        active: 1,
        ids: []
      },
      statusVariants:
          {
            "ACTIVE": "success", "PENDING": "warning", "SHIPPED": "success",
            "PAID": "success", "WAITING_FOR_PAYMENT": "warning", "PAYMENT_FAILED": "danger",
            "CANCELLED": "danger", "COMPLETED": "success"
          },

      propertyFilter: {
        creationDateFrom: null,
        creationDateUtil: null,
        assignationDateFrom: null,
        assignationDateUtil: null,
        active: 1,
        ids: []
      },
      dialogs: {
        client: false,
      },
      uploadFile: false,
      buildingUpdated: false,
      //Listeners
      listeners: {
        submit: () => {
        }
      },
    }
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    },
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('building', ['fileTags', 'allStatuse', 'canOperateBuilding']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllLanguage', 'getAllBranches', 'getAllCountries', 'getAdminRoleId']),
    ...mapGetters('refinance', ['allStatus', 'allTypes']),

    refinanceType() {
      return this.allTypes.map(status => ({
        value: status.value,
        text: this.msg(status.text)
      }))
    },
    allStatus() {
      return this.allStatuse.map(status => ({
        value: status.value,
        text: status.text.toUpperCase()
      }))
    },
    canSubmit: {
      get() {
        return false
      },
      save(v) {
        this.$emit('update:canSave', v)
      }
    },
    dropzoneOptions() {
      if (this.building == null || this.building.id == null)
        return {};
      return {
        url: window.config.apiUrl + 'building/upload/file/' + this.building.id,
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 5000000,
        acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx",
      }
    },
    dropzoneImagesOptions() {
      if (this.building == null || this.building.id == null)
        return {};
      return {
        url: window.config.apiUrl + 'building/upload/img',
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`, 'buildingId': this.building.id},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 5000000,
        acceptedFiles: 'image/*,video/*,.mp4',
      }
    },
    renterCount: {
      get() {
        return "coming soon"
      }
    },

    ownerCount: {
      get() {
        return "coming soon"
      }
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  created() {
    if (this.$route.params.id == null) {
      return this.error = 'Invalid building id'
    }
    this.id = this.$route.params.id;
    this.onLoad()
  },

  methods: {
    ...mapActions('building', {_getBuilding: 'getBuildingById', _updateBuilding: 'updateBuilding',}),
    ...mapActions('refinance', ['addRefinance']),
    ...mapActions('building', ['updateDocumentFile']),
    ...mapActions("document", ["downloadCustomFile"]),
    ...mapActions('building', ['sendChoosenFilesToMail']),
    sendAllFilesByMail() {

      this.sendChoosenFilesToMail({
        buildingId: this.building.id,
        email: this.emailAddress,
        filesId: this.selectedFileIds,
        isBank: false
      });

    },
    onDelete() {
      console.log("onDelete id ")

      let $this = this

      this._updateBuilding(this.building)
          .then(
              (a) => {
                $this.$emit('submitted', {
                  status: true,
                  server: a
                })
                $this.loadBuilding()

              },
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          ).finally(
          () => {
            $this.onLoad()
            $this.newPropertyId = null;
          }
      )
    },
    uploadImageComplete(file, response) {
      this.loadBuilding()

      console.log('uploadImageComplete', {file, response})
    },
    uploadImageSuccess(file, response) {
      try {
        if (response.data == null || response.data['Building'] == null)
          return;
        this.building.images = response.data['Building']['images'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    info(value) {
      this.infoModal.value = value;
      this.infoModal.title = 'Ask for a refinance for this building'
      this.$root.$emit('bv::show::modal', this.infoModal.id)
    },
    goToRefinance() {
      if (this.building.refinanceId != null)
        this.$router.push(`/refinance/edit/${this.building.refinanceId}`);
    },
    addClientToBuilding() {

      let $this = this
      if (this.building.clientIds === null)
        this.building.clientIds = [];
      this.building.clientIds.push(this.newClientId)

      console.log("addClientToBuilding im here1")
      this._updateBuilding(this.building)
          .then(
              (a) => {
                $this.$emit('submitted', {
                  status: true,
                  server: a
                })

                $this.clientFilter.ids = $this.building.clientIds;

              },
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          ).finally(() => {

        $this.newClientId = null;

      })

    },
    addPropertyToBuilding() {

      let $this = this
      if (this.building.propertyIds == null)
        this.building.propertyIds = [];
      this.building.propertyIds.push(this.newPropertyId)
      console.log("addPropertyToBuilding im here1")
      this._updateBuilding(this.building)
          .then(
              (a) => {
                $this.$emit('submitted', {
                  status: true,
                  server: a
                })
                console.log("addPropertyToBuilding im here2")

                $this.loadBuilding()

              },
              (a) => $this.$emit('submitted', {

                status: false,
                server: a
              })
          ).finally(() => {
        console.log("addPropertyToBuilding im here3")

        $this.newPropertyId = null;
        $this.load()

      })

    },
    convertToEURO(someNumber) {
      if (someNumber == null || isNaN(someNumber))
        return "-";
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    replaceIfNotNull(data) {
      if (data == null || typeof data !== "string")
        return data;
      return data.replace('€', '').replaceAll(',', '');

    },
    uploadComplete(file, response) {
      let responseData = JSON.parse(file.xhr.responseText);
      let building = responseData.objects["Building"];
      this.building = building;
    },
    uploadSuccess(file, response) {
      try {
        if (response.data == null || response.data['Building'] == null)
          return;
        this.building = response.data['Building'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    },

    submitDocumentFile(index) {
      this.updateDocumentFile({
        id: this.building.id,
        file: this.building.files[index]
      });
    },
    getFileSize(bytes, si = false, dp = 1) {
      const thresh = si ? 1000 : 1024;

      if (Math.abs(bytes) < thresh) {
        return '';
      }

      const units = si
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      const r = 10 ** dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


      return ' (' + bytes.toFixed(dp) + ' ' + units[u] + ')';
    },
    createRefinance() {
      let $this = this;
      this.addRefinance({id: this.building.id, type: this.infoModal.value}).then(ans => {

        $this.building.refinanceId = ans;
        $this.resetInfoModal()
      });
    },
    onDownloadFile(fileId) {
      let $this = this;
      this.downloadCustomFile({fileId: fileId}).then(file => {
        $this.asyncDownloadFile(file)
      })
    },
    onPreviewFile(fileId) {
      let $this = this;
      this.downloadCustomFile({fileId: fileId}).then(file => {

        let url = URL.createObjectURL(file.data);
        console.log("url is: ", url)
        window.open(url, '_blank')
      })
    },
    iconByExt(file) {
      if (file == null) {
        return {fas: true, 'fa-file-alt': true};
      }
      let ext = file.substr(file.lastIndexOf('.') + 1)
      if (ext === 'pdf') {
        return {far: true, 'fa-file-pdf': true}
      }
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gift' || ext === 'bmp') {
        return {far: true, ' fa-file-image': true}
      }
      if (ext === 'doc' || ext === 'docx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'xls' || ext === 'xlsx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'zip' || ext === 'rar' || ext === '7zip') {
        return {far: true, ' fa-file-archive': true}
      }
      return {fas: true, 'fa-file-alt': true}
    },
    onClientSelect(client) {
      this.selected = client.id
      this.dialogs.client = true
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', client.id)

    },
    onLoad() {
      this.loaded = false

      this.loadBuilding()

    },

    loadBuilding() {
      let $this = this
      console.log("addPropertyToBuilding im here4")

      this._getBuilding($this.id).then((building) => {

        $this.building = BuildingModel(building)
        //Set page as loaded
        if (building.clientIds == null || building.clientIds.length < 1)
          $this.clientFilter.ids = [0];
        else $this.clientFilter.ids = building.clientIds;

        $this.loaded = true
        $this.buildingUpdated = false;
      }, (error) => {
        $this.error = error
      })
    }
    ,

    updateBuilding() {
      let $this = this
      this._updateBuilding(this.building)
          .then(
              (a) => $this.$emit('submitted', {
                status: true,
                server: a
              }),
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          ).finally(
          () => {
            $this.onLoad()
          }
      )
    },
    validate(field) {
      return this.$v.building[field] != null && this.$v.building[field].$error === true
    },
    submit() {
      if (this.id !== 0) {
        return this.updateBuilding()
      }
      return this.createBuilding()

    },


  },

}
</script>

<style scoped>
.realEstate-page .update-realEstate {
  position: fixed;
  bottom: 5%;
  z-index: 100;
  transition: all 0.5s ease;
  right: 79px;
  opacity: 0;
}

.realEstate-page .update-realEstate.active {
  opacity: 1;
}

.realEstate-page .update-realEstate .btn-primary {
  -webkit-box-shadow: 0 1px 20px 1px #7367f0 !important;
  box-shadow: 0 1px 20px 1px #7367f0 !important;
}

.realEstate-page .update-realEstate .btn-warning {
  -webkit-box-shadow: 0 1px 20px 1px #ff8510 !important;
  box-shadow: 0 1px 20px 1px #ff8510 !important;
}

.realEstate-page .dz-preview, .dz-file-preview {
  display: none;
}

.popover-body {

  width: 250px;

}
</style>
